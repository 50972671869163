import settingsDirectAddressesTemplate from "./direct-addresses/settings.direct-addresses.html";
import settingsFaxTemplate from "./fax/settings.fax.html";
import settingsDownloadProfilesTemplate from "./download-profiles/settings.download-profiles.html";
import settingsAddonsTemplate from "./addons/settings.addons.html";
import settingsBillingTemplate from "./billing/settings.billing.html";
import settingsLicensedPlanTemplate from "./licensed-plan/settings.licensed-plan.html";
import settingsReportsTemplate from "./reports/settings.reports.html";
import settingsUsersTemplate from "./users/settings.users.html";
import settingsOrganizationTemplate from "./organization/settings.organization.html";
import settingsRulesTemplate from "./rules/settings.rules.html";
import documentTypesTemplate from "./document-types/document-types.html";
import releaseTypesTemplate from "./release-types/release-types.html";
import settingsExportsTemplate from "./exports/settings.exports.html";

angular.module("kno2.settings").config([
    "$stateProvider",
    "$urlRouterProvider",
    function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            // Settings: Top menu
            .state("settingsDirectaddresses", {
                url: "/settings/directaddresses",
                parent: "home",
                templateUrl: settingsDirectAddressesTemplate,
                controller: "SettingsDirectAddressesCtrl",
                data: { roles: ["Administrator"] }
            })
            .state("settingsFax", {
                url: "/settings/fax",
                parent: "home",
                templateUrl: settingsFaxTemplate,
                controller: "SettingsFaxCtrl",
                data: { roles: ["Administrator"] }
            })
            .state("settingsDownloadProfiles", {
                url: "/settings/downloadprofiles",
                parent: "home",
                params: { request: null },
                templateUrl: settingsDownloadProfilesTemplate,
                controller: "SettingsDownloadProfilesCtrl",
                data: { roles: ["Administrator"] }
            })
            /*.state('/settings/security', { url: '/settings/security', templateUrl: 'settings-security', controller: 'SettingsSecurityCtrl', data: { roles: ['Administrator'] }, })*/
            .state("settingsAddons", {
                url: "/settings/addons",
                parent: "home",
                templateUrl: settingsAddonsTemplate,
                data: { roles: ["Administrator"] },
                controller: "SettingsAddonsCtrl as main",
                resolve: {
                    devices: [
                        "DevicesService",
                        function (DevicesService) {
                            return DevicesService.getPages();
                        }
                    ],
                    apps: [
                        "AppsService",
                        function (AppsService) {
                            return AppsService.getPages();
                        }
                    ],
                    licenses: [
                        "LicensesService",
                        function (LicensesService) {
                            return LicensesService.getPages();
                        }
                    ]
                }
            })
            .state("settingsBilling", {
                url: "/settings/billing",
                parent: "home",
                templateUrl: settingsBillingTemplate,
                controller: "SettingsBillingCtrl",
                data: { roles: ["Administrator"] },
                resolve: {
                    load: [
                        "$q",
                        "$location",
                        "SessionService",
                        "CommonData",
                        function ($q, $location, sessionService, commonData) {
                            var defer = $q.defer();
                            var profile = sessionService.getProfile();

                            if (profile.resellerType == commonData.resellerTypes.findByValue("Licensing").value) {
                                $location.path("/settings/licensedplan");
                            } else {
                                defer.resolve();
                            }

                            return defer.promise;
                        }
                    ]
                }
            })
            .state("settingsLicensedplan", {
                url: "/settings/licensedplan",
                parent: "home",
                templateUrl: settingsLicensedPlanTemplate,
                controller: "SettingsLicensedPlanCtrl",
                data: { roles: ["Administrator"] },
                resolve: {
                    load: [
                        "$q",
                        "$location",
                        "SessionService",
                        "CommonData",
                        function ($q, $location, sessionService, commonData) {
                            var defer = $q.defer();
                            var profile = sessionService.getProfile();

                            if (profile.resellerType != commonData.resellerTypes.findByValue("Licensing").value) {
                                $location.path("/settings/billing");
                            } else {
                                defer.resolve();
                            }

                            return defer.promise;
                        }
                    ]
                }
            })
            .state("settingsReports", {
                url: "/settings/reports",
                parent: "home",
                templateUrl: settingsReportsTemplate,
                controller: "SettingsReportsCtrl",
                data: { roles: ["Administrator"] }
            })
            .state("settingsApi", {
                abstract: true,
                url: "/settings/api",
                parent: "home",
                component: "k2Api",
                data: { roles: ["Administrator"] }
            })
            .state("settingsApi.legacy", {
                url: "",
                component: "k2ApiLegacy",
                data: { roles: ["Administrator"] }
            })
            .state("settingsApi.network", {
                url: "/network",
                component: "k2ServiceAccount",
                data: { roles: ["NetworkAdmin"] },
                resolve: {
                    type: function () {
                        return "network";
                    },
                    group: [
                        "SessionService",
                        "NetworksService",
                        function (SessionService, NetworksService) {
                            var profile = SessionService.getProfile();
                            return NetworksService.getNetwork(profile.administerOfNetworkObjectId);
                        }
                    ]
                }
            })
            .state("settingsApi.carePlan", {
                url: "/careplan",
                component: "k2ApiCarePlan",
                data: { roles: ["Administrator"] }
            })
            .state("settingsIpAddresses", {
                abstract: true,
                url: "/settings/ipaddresses",
                parent: "home",
                component: "k2IpAddresses",
                data: { roles: ["Administrator"] }
            })
            .state("settingsIpAddresses.whitelist", {
                url: "",
                component: "k2IpaddressesWhitelist",
                data: { roles: ["Administrator"] }
            })
            .state("settingsIdentityProviders", {
                abstract: true,
                url: "/settings/identityproviders",
                parent: "home",
                component: "k2IdentityProvidersSettings",
                data: { roles: ["Administrator"] }
            })
            .state("settingsIdentityProviders.setup", {
                url: "",
                component: "k2IdentityProviders",
                data: { roles: ["Administrator"] }
            })
            // Settings: Left menu
            .state("settingsUsers", {
                url: "/settings/users",
                parent: "home",
                templateUrl: settingsUsersTemplate,
                controller: "UsersCtrl",
                data: { roles: ["Administrator"] }
            })
            .state("settingsOrganization", {
                url: "/settings/organization",
                parent: "home",
                templateUrl: settingsOrganizationTemplate,
                controller: "OrganizationCtrl",
                controllerAs: "$ctrl",
                data: { roles: ["Administrator"] },
                resolve: {
                    organization: [
                        "SessionService",
                        "OrganizationService",
                        function (SessionService, OrganizationService) {
                            var profile = SessionService.getProfile();
                            return OrganizationService.getOrganizationDto(profile.organizationId);
                        }
                    ],
                    plan: [
                        "OrganizationService",
                        function (OrganizationService) {
                            return OrganizationService.getSubscription();
                        }
                    ]
                }
            })
            .state("settingsRules", {
                url: "/settings/rules",
                parent: "home",
                templateUrl: settingsRulesTemplate,
                controller: "SettingsRulesCtrl",
                controllerAs: "$ctrl",
                data: { roles: ["Administrator"] }
            })
            // Setting: Interoperability
            .state("settingsDocumenttypes", {
                url: "/settings/documenttypes",
                parent: "home",
                templateUrl: documentTypesTemplate,
                controller: "DocumentTypesCtrl",
                data: { roles: ["Administrator"] }
            })
            .state("settingsReleasetypes", {
                url: "/settings/releasetypes",
                parent: "home",
                templateUrl: releaseTypesTemplate,
                controller: "ReleaseTypesCtrl",
                data: {
                    roles: ["Administrator"],
                    resource: "releaseTypesRoute"
                }
            })
            .state("settingsFaxAccount", {
                url: "/settings/faxaccount",
                parent: "home",
                component: "k2FaxAccount"
            })
            .state("settingsEFaxAccount", {
                url: "/settings/efaxaccount",
                parent: "home",
                component: "k2EFaxAccount"
            })
            .state("settingsCdaDocumentTypes", {
                url: "/settings/cdadocumenttypes",
                parent: "home",
                component: "k2CdaDocumentTypes",
                data: { roles: ["Administrator"] }
            })
            .state("settingsExports", {
                url: "/settings/exports",
                parent: "home",
                templateUrl: settingsExportsTemplate,
                controller: "SettingsExportsCtrl",
                data: { roles: ["Administrator"] }
            })
            .state("oauthAuthorizationCodeCallback", {
                url: "/oauth/callback/:type",
                component: "k2AuthorizationCodeCallbackComponent",
                parent: "renewtokens"
            });
    }
]);
