import accountDashboardTemplate from "./dashboard/account.dashboard.html";
import editProfileModalTemplate from "./profile/account.profile.edit-profile-modal.html";
import accountNotificationsTemplate from "./notifications/account.notifications.html";

accountRoutes.$inject = ["$stateProvider"];
export function accountRoutes($stateProvider) {
    $stateProvider
        .state("accountDashboard", {
            url: "/account/dashboard",
            parent: "home",
            templateUrl: accountDashboardTemplate,
            controller: "DashboardCtrl",
            data: { roles: ["Administrator"] }
        })
        .state("accountProfile", {
            url: "/account/profile",
            parent: "home",
            templateUrl: editProfileModalTemplate,
            controller: "EditProfileModalCtrl",
            controllerAs: "$ctrl",
            windowClass: "edit-profile-modal",
            data: {
                modal: true
            },
            resolve: {
                showNotificationsTab: function () {
                    return false;
                },
                userId: [
                    "SessionService",
                    function (sessionService) {
                        return sessionService.getProfile().userId;
                    }
                ]
            }
        })
        .state("accountProfileEmail", {
            url: "/account/profile/email",
            parent: "home",
            templateUrl: editProfileModalTemplate,
            controller: "EditProfileModalCtrl",
            controllerAs: "$ctrl",
            windowClass: "edit-profile-modal",
            modal: true,
            resolve: {
                showNotificationsTab: function () {
                    return true;
                },
                userId: [
                    "SessionService",
                    function (sessionService) {
                        return sessionService.getProfile().userId;
                    }
                ]
            }
        })
        .state("accountNotifications", {
            url: "/account/notifications?selectedTab",
            parent: "home",
            templateUrl: accountNotificationsTemplate,
            controller: "NotificationsCtrl",
            data: {
                roles: ["Administrator", "User"]
            }
        })
        .state("accountActivate", {
            url: "/account/register",
            component: "k2AccountActivate"
        });
}
