import templateUrl from "./request.grid.component.html";

angular.module("kno2.records").component("k2RequestGrid", {
    controller: RequestGridCtrl,
    templateUrl
});

RequestGridCtrl.$inject = ["$scope", "$timeout", "EventService", "ReleaseTypesService", "LocalStorageFactory", "SyncService", "appConfig"];

function RequestGridCtrl($scope, $timeout, EventService, ReleaseTypesService, LocalStorageFactory, SyncService, appConfig) {
    var ctrl = this;

    var REQUEST_RECENT_EVENTS_PAGE_KEY = "request.recentEventsPage";

    ctrl.brandDisplayName = appConfig.brandDisplayName;
    ctrl.$onInit = $onInit;
    ctrl.attachWorkflowIdToEvent = attachWorkflowIdToEvent;
    ctrl.pageChanged = loadRecentEvents;

    function $onInit() {
        ctrl.events = { items: [], pageSize: 15 };
        ctrl.releaseTypes = null;
        ctrl.tabs = {
            events: { active: true }
        };

        ReleaseTypesService.getReleaseTypes().then(function (response) {
            ctrl.releaseTypes = response.releaseTypes;
        });

        SyncService.registerForBroadcast(function () {
            loadRecentEvents();
        }, $scope);

        $timeout(function delayCurrentPageLoading() {
            loadRecentEvents().then(function () {
                var eventsCurrentPage = parseInt(LocalStorageFactory.get(REQUEST_RECENT_EVENTS_PAGE_KEY), 10);
                ctrl.events.currentPage = isNaN(eventsCurrentPage) ? 1 : eventsCurrentPage;
            });
        });
    }

    function loadRecentEvents() {
        return EventService.getMyRecentRequestEvents(10, ctrl.events.currentPage).then(function (response) {
            ctrl.events.items = response.data.items;
            ctrl.events.count = response.data.items.length;
            ctrl.events.totalCount = response.data.totalCount;
            _.each(ctrl.events.items, attachWorkflowIdToEvent);
        });
    }

    function attachWorkflowIdToEvent(event) {
        if (!_.isEmpty(event.subject)) {
            var releaseType = _.find($scope.releaseTypes, {
                name: event.subject
            });
            event.releaseType = releaseType ? releaseType.id : "";
        }
    }
}
