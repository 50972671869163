angular.module("kno2.services").service("MessageService", MessageService);
// This service contains functionality for Documents and Attachments, as the latter
// are accessed through the document.
MessageService.$inject = [
    "$http",
    "$timeout",
    "$interval",
    "$q",
    "moment",
    "_",
    "CacheService",
    "SaveService",
    "AttachmentService",
    "DocumentProcessingService",
    "LocalStorageFactory",
    "FeatureService"
];
function MessageService(
    $http,
    $timeout,
    $interval,
    $q,
    moment,
    _,
    CacheService,
    SaveService,
    AttachmentService,
    DocumentProcessingService,
    LocalStorageFactory,
    FeatureService
) {
    const self = this;
    const SELECTED_ATTACHMENT_KEY = "intakeMessage.initialAttachmentId";

    this.getMessage = function (messageId) {
        const promise = $http.get("/api/messages/" + messageId).then(
            function (response) {
                /*2014-10-09 IKW-1259 PR: Must not show SQL min date for the document date.*/
                angular.forEach(response.data.attachments, function (value) {
                    if (value.attachmentMeta && value.attachmentMeta.documentDate === "1753-01-01T00:00:00") {
                        value.attachmentMeta.documentDate = "";
                    }
                });
                return response.data;
            },
            function (data) {
                throw "error";
            }
        );
        return promise;
    };

    this.getRequest = function (params, useOptimizedIntake) {
        const startDate = moment().utc().subtract(30, "days").startOf("day").toDate();

        if (params.isAll || params.isUnassigned) {
            params.rules = []; // Clear rules in this situation.
        }

        // Just passed the necessary properties from rules array (to reduce url and not sending unused properties).
        const parsedRules = useOptimizedIntake
            ? _.map(params.rules || [], function (rule) {
                  return rule.id;
              })
            : _.map(params.rules || [], function (rule) {
                  return {
                      toAddress: rule.toAddress,
                      fromAddress: rule.fromAddress,
                      sourceType: rule.sourceType
                  };
              });

        const theRules = params.dontStringify ? parsedRules : JSON.stringify(parsedRules) || [];

        const req = {
            pageStart: params.pageNumber,
            orderDir: params.sortDir || "desc",
            orderBy: params.orderBy || "receiveDate",
            startDate: params.startDate || startDate.toJSON(),
            endDate: params.endDate,
            rules: theRules,
            statuses: params.statuses || [],
            isAll: params.isAll,
            isUnassigned: params.isUnassigned,
            sourceTypes: params.sourceTypes || [],
            isAssignedToSelf: params.isAssignedToSelf,
            processedTypes: params.processedTypes || [],
            sources: params.sources || [],
            filter: params.filter
        };
        return req;
    };

    this.getMessages = function (params, preferOptimizedIntake) {
        let useOptimizedIntake = false;
        if (preferOptimizedIntake !== false) useOptimizedIntake = FeatureService.isEnabled("UseOptimizedIntake");
        const req = this.getRequest(params, useOptimizedIntake);
        let controller = "messages";
        if (useOptimizedIntake) {
            controller = "intake";
        }
        return $http.post(`/api/${controller}`, req);
    };

    this.getDocumentSortItems = function () {
        return {
            data: [
                {
                    id: "receiveDate",
                    name: "received date",
                    order: { asc: "oldest on top", desc: "newest on top" }
                },
                {
                    id: "updatedDate",
                    name: "updated date",
                    order: { asc: "oldest on top", desc: "newest on top" }
                }
            ]
        };
    };

    this.savePatientAndAttachments = async function (messageId, patientDetails, attachments) {
        await this.savePatientDetails(messageId, patientDetails);
        if (attachments && attachments.length) {
            await this.saveAttachmentsDetails(messageId, attachments);
            await this.transformAttachments(messageId, attachments);
        }
    };

    this.savePatientDetails = function (messageId, patient) {
        // Fix to remove any blank patient ids (KPI-35619)
        patient.patientIds = patient.patientIds.filter(function (x) {
            return x.root != null && x.id != null;
        });

        CacheService.clear("/messages"); // Clear cache when save to force messages reload
        return $http.put("/api/messages/{id}/patient".replace("{id}", messageId), patient);
    };

    this.transformAttachments = function (messageId, attachments) {
        attachments.forEach(function (attachment) {
            const patient = attachment.attachmentMeta && attachment.attachmentMeta.patient;
            if (patient) {
                patient.patientIds = patient.patientIds.filter(function (x) {
                    return x.root != null && x.id != null;
                });
            }
        });

        return AttachmentService.transformAttachments(messageId, attachments);
    };

    this.saveAttachmentsDetails = function (messageId, attachments) {
        // Fix to remove any blank patient ids (KPI-35619)
        attachments.forEach(function (attachment) {
            const patient = attachment.attachmentMeta && attachment.attachmentMeta.patient;
            if (patient) {
                patient.patientIds = patient.patientIds.filter(function (x) {
                    return x.root != null && x.id != null;
                });
            }
        });
        return AttachmentService.saveAttachmentDetails(messageId, attachments);
    };

    this.delete = function (message) {
        const id = message.id ? message.id : message;
        const promise = $http.delete("/api/messages/{id}".replace("{id}", id));
        return promise;
    };

    this.getDrafts = function (params) {
        params.isDraft = true;

        const promise = $http({
            method: "GET",
            url: "/api/messages",
            params: params
        });
        return promise;
    };

    this.getSignatureDrafts = function (params) {
        params.isDraft = true;

        const promise = $http({
            method: "GET",
            url: "/api/messages/signatures",
            params: params
        });
        return promise;
    };

    this.getSigners = (messageId) => {
        return $http.get(`/api/messages/${messageId}/signers`).then((response) => {
            return response.data;
        });
    };

    this.getDraft = function (draftId) {
        return $http.get("/api/messages/" + draftId).then(function (response) {
            return response.data;
        });
    };

    this.createDraft = function (draftId) {
        return $http.post("/api/messages/" + draftId + "/copy").then(function (response) {
            return response.data;
        });
    };

    this.saveProcessedMessage = function (messageId, isProcessed, subject, processType) {
        return $http.put("/api/messages/{id}/process".replace("{id}", messageId), {
            isProcessed: isProcessed,
            subject: subject,
            processType: processType
        });
    };

    this.getClassifications = function () {
        return $http.get("/api/messages/classifications").then(function (response) {
            return response.data;
        });
    };

    this.getDeliverySummary = function (messageId) {
        const request = {
            url: `/api/messages/${messageId}/deliverysummary`,
            method: "GET",
            headers: { Accept: "application/pdf" },
            responseType: "arraybuffer"
        };

        return $http(request);
    };

    this.pollMessageUntil = function (messageId, predicate, interval, timeout, token) {
        return doUntil(
            function () {
                return self.getMessage(messageId);
            },
            predicate,
            interval,
            timeout,
            token
        );
    };

    this.shouldWaitForTransforms = function (message) {
        const pending = _.filter(message.attachments, { transformStatus: "Pending" });
        return _.some(pending);
    };

    this.waitForTransforms = function (messageId) {
        return $q(function (resolve, reject) {
            self.getMessage(messageId).then(function (message) {
                const shouldWait = self.shouldWaitForTransforms(message);
                if (!shouldWait) return resolve();

                const wait = 20000; // 20 seconds
                const timeout = $timeout(cancel, wait);

                const action = "transform";
                const pending = _.filter(message.attachments, { transformStatus: "Pending" });
                const promises = _.map(pending, function (a) {
                    return DocumentProcessingService.waitFor(action, a.id);
                });

                const token = {};
                self.pollMessageUntil(messageId, predicate, wait / 4, wait, token)
                    .then(stopWaiting)
                    .then(complete)
                    .catch(cancel);

                $q.all(promises).then(token.cancel).then(complete).catch(angular.noop);

                function cancel() {
                    $timeout.cancel(timeout);
                    reject("The request timed out. Please try again.");
                }

                function complete() {
                    $timeout.cancel(timeout);
                    resolve();
                }

                function predicate(message) {
                    return !self.shouldWaitForTransforms(message);
                }

                function stopWaiting() {
                    const promises = _.map(pending, function (a) {
                        return DocumentProcessingService.stopWaitingFor(action, a.id);
                    });
                    return $q.all(promises);
                }
            });
        });
    };

    this.waitForConversion = function (messageId) {
        return $q(function (resolve, reject) {
            self.getMessage(messageId).then(function (message) {
                const action = "conversion";
                const pending = getProcessing(message);

                const shouldWait = _.some(pending);
                if (!shouldWait) return resolve();

                const wait = 20000; // 20 seconds
                const timeout = $timeout(cancel, wait);

                const promises = _.map(pending, function (a) {
                    return DocumentProcessingService.waitFor(action, a.id);
                });

                const token = {};
                self.pollMessageUntil(messageId, predicate, wait / 4, wait, token)
                    .then(stopWaiting)
                    .then(complete)
                    .catch(cancel);

                $q.all(promises).then(token.cancel).then(complete).catch(angular.noop);

                function cancel() {
                    $timeout.cancel(timeout);
                    reject("The request timed out. Please try again.");
                }

                function complete() {
                    $timeout.cancel(timeout);
                    resolve();
                }

                function predicate(message) {
                    const pending = getProcessing(message);
                    return !_.some(pending);
                }

                function getProcessing(message) {
                    return _.filter(message.attachments, { previewAvailable: "Processing" });
                }

                function stopWaiting() {
                    const promises = _.map(pending, function (a) {
                        return DocumentProcessingService.stopWaitingFor(action, a.id);
                    });
                    return $q.all(promises);
                }
            });
        });
    };

    this.setInitalAttachmentId = (attachmentId) => {
        LocalStorageFactory.set(SELECTED_ATTACHMENT_KEY, attachmentId);
    };

    this.getInitialAttachmentId = () => {
        return LocalStorageFactory.get(SELECTED_ATTACHMENT_KEY);
    };

    this.removeInitialAttachmentId = () => {
        LocalStorageFactory.remove(SELECTED_ATTACHMENT_KEY);
    };

    function doUntil(fn, predicate, interval, timeout, token) {
        return $q(function (resolve, reject) {
            if (token) token.cancel = cancel;

            let t,
                i = $interval(func, interval);
            if (timeout) t = $timeout(cancel, timeout);

            func();

            function func() {
                $q.resolve(fn())
                    .then(function (result) {
                        if (predicate(result)) complete(result);
                    })
                    .catch(cancel);
            }

            function complete(message) {
                clear();
                resolve(message);
            }

            function cancel() {
                clear();
                reject();
            }

            function clear() {
                $interval.cancel(i);
                $timeout.cancel(t);
            }
        });
    }
}
