(function () {
    "use strict";

    angular.module("kno2.core").factory("RedirectService", RedirectService);

    RedirectService.$inject = ["SessionService", "LocalStorageFactory"];

    var RETURN_URL_KEY = "login.returnUrl";

    /// Redirects the user a returnUrl if set, or the dashboard if admin
    function RedirectService(SessionService, LocalStorageFactory) {
        return {
            redirect: redirect
        };

        function redirect() {
            var path = "";

            if (SessionService.userInRole(["SysAdmin", "SysAdminReporting"])) path = "/dashboard/messaging";
            else if (SessionService.userInRole("Administrator")) path = "/account/dashboard";
            else if (SessionService.userInRole("User")) path = "/intake";

            if (LocalStorageFactory.get(RETURN_URL_KEY)) path = LocalStorageFactory.get(RETURN_URL_KEY);
            else if (LocalStorageFactory.cookie.get(RETURN_URL_KEY)) path = LocalStorageFactory.cookie.get(RETURN_URL_KEY);

            LocalStorageFactory.remove(RETURN_URL_KEY);
            LocalStorageFactory.cookie.remove(RETURN_URL_KEY, LocalStorageFactory.getDomain());

            return path;
        }
    }
})();
