angular.module("kno2.shared.settings").controller("ReleaseTypesEditModalCtrl", [
    "$rootScope",
    "$scope",
    "$q",
    "$timeout",
    "$uibModalInstance",
    "$confirm",
    "data",
    "ReleaseTypesService",
    "NotificationService",
    "SessionService",
    "SubscriptionService",
    "DocumentSourceService",
    "UsersService",
    "removeNonNumericFromFax",
    "FeatureService",
    "MessageService",
    function (
        $rootScope,
        $scope,
        $q,
        $timeout,
        $uibModalInstance,
        $confirm,
        data,
        ReleaseTypesService,
        NotificationService,
        SessionService,
        SubscriptionService,
        DocumentSourceService,
        UsersService,
        removeNonNumericFromFax,
        FeatureService,
        MessageService
    ) {
        $scope.setForm = setForm;
        $scope.cancel = cancel;
        $scope.saveChanges = saveChanges;

        activate();

        function activate() {
            $scope.parseFaxAddressInput = function (input) {
                $scope.releaseType.destinationAddress = removeNonNumericFromFax(input);
            };

            var profile = SessionService.getProfile();
            $scope.isSysAdmin = _(profile.roles).includes("SysAdmin");

            // This provides the necessary call information for the k2-unique directive.
            $scope.releaseTypeExists = function () {
                return {
                    serviceFunction: ReleaseTypesService.isReleaseTypeExists,
                    errorMessage: "This release type already exists, please enter a different one."
                };
            };

            FeatureService.load().then(function () {
                $scope.destinationAddressPlaceholder = FeatureService.isEnabled("FaxDocumentSource") ? "Address or Fax Number" : "Address";
            });

            MessageService.getClassifications().then(function (data) {
                $scope.classifications = data;
            });

            if (data.id) {
                ReleaseTypesService.getReleaseType(data.id).then(function (result) {
                    $scope.releaseType = result;
                    $timeout(function () {
                        $("input[name*='releaseTypeName']").focus().select();
                    }, 500);
                });
            } else {
                $scope.releaseType = {
                    name: "",
                    OrganizationId: profile.organizationId
                };
                $timeout(function () {
                    $("input[name*='releaseTypeName']").focus().select();
                }, 500);
            }

            if (!$scope.isSysAdmin) {
                DocumentSourceService.getDocumentSourcesByTenantAndUserRole(profile.userId).then(function (data) {
                    $scope.documentSources = data;
                });

                UsersService.getOrgUsers(false).then(function (data) {
                    $scope.users = data;
                });
            }
        }

        function setForm(form) {
            $scope.form = form;
        }

        function cancel() {
            $uibModalInstance.dismiss("cancel");
        }

        function promptForUpgrade(address) {
            var upgrade = true;
            if (!address || address.length < 1) return false;
            if (address.indexOf("kno2fy") > -1) {
                upgrade = false;
            }
            var faxSourceExists = _.some($scope.documentSources, {
                type: "Fax"
            });
            if (faxSourceExists) {
                return isFaxNumber(address);
            }
            return upgrade;
        }

        function isFaxNumber(address) {
            DocumentSourceService.isValidFaxAddress(address).then(function (data) {
                var isValidFax = data.isValidFax;
                return isValidFax;
            });
        }

        function onFreePlan() {
            return $rootScope.hasUIResourceAction("releaseGridActionCell", "hide");
        }

        function saveChanges() {
            // Form validations
            $scope.validationInProgress = true;

            // If on free plan check to see if destinationAddress is a non-kno2 address
            if (onFreePlan()) {
                if (promptForUpgrade($scope.releaseType.destinationAddress)) {
                    SubscriptionService.checkAndShowPrompt("releaseTypesGridLink");
                    $scope.form.$valid = false; // stop Ladda
                    $scope.validationInProgress = false;
                    return;
                }
            }

            // Save changes if form valid
            if ($scope.form.$valid) {
                if ($scope.releaseType.destinationAddress) $scope.parseFaxAddressInput($scope.releaseType.destinationAddress);

                var functionName = $scope.releaseType.id ? "putReleaseType" : "postReleaseType";
                ReleaseTypesService[functionName]($scope.releaseType)
                    .then(
                        function (response) {
                            NotificationService.success("The release type was saved successfully.");
                            $uibModalInstance.close($scope.releaseType);
                        },
                        function (response) {
                            NotificationService.serverError(
                                response.data.modelState,
                                response.data.message ? response.data.message : "There was an error saving this release type."
                            );
                        }
                    )
                    .finally(function () {
                        $scope.validationInProgress = false;
                    });
            } else {
                $scope.validationInProgress = false;
            }
        }
    }
]);
