import templateUrl from "./auth0-logout.component.html";

class Auth0LogoutController {
    constructor($timeout, $location, Auth0Service, auth0ResponseConstants, LocalStorageFactory) {
        this.$timeout = $timeout;
        this.$location = $location;
        this.auth0Service = Auth0Service;
        this.auth0ResponseConstants = auth0ResponseConstants;
        this.localStorageFactory = LocalStorageFactory;
        // this.keepaliveProvider = KeepaliveProvider;
    }

    $onInit() {
        // this.keepaliveProvider.stop();
        this.loginText = "Login";
    }

    login() {
        this.auth0Service.login(this.loginType);
    }

    clearLogin() {
        this.auth0Service.login();
    }
}

Auth0LogoutController.$inject = ["$timeout", "$location", "Auth0Service", "auth0ResponseConstants", "LocalStorageFactory"];

export const auth0LogoutComponent = {
    templateUrl: templateUrl,
    controller: Auth0LogoutController
};
