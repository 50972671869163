import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";

export const tokenGuard: CanActivateFn = async (route) => {
    const upgrade = inject(UpgradeModule);
    const authService = upgrade.$injector.get("Auth0Service");

    if (!authService.getAccessToken()) {
        await authService.renewTokens();
    }

    return true;
};
