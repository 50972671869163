import settingsDownloadProfilesEditModalTemplate from "./settings.download-profiles.edit-modal.html";
import settingsDownloadProfilesDeleteModalTemplate from "./settings.download-profiles.delete-modal.html";

angular.module("kno2.settings").controller("SettingsDownloadProfilesCtrl", [
    "$scope",
    "$timeout",
    "PagingFactory",
    "ModalFactory",
    "DownloadProfilesService",
    "$state",
    function ($scope, $timeout, PagingFactory, ModalFactory, DownloadProfilesService, $state) {
        $scope.profiles = [];

        $scope.formatExportType = function (value) {
            if (value === "Pdf") return value.toUpperCase();
            return value;
        };

        $scope.paging = PagingFactory.paging({ sortBy: "name" });

        $scope.editProfile = function (id) {
            var data = { id: id };
            ModalFactory.editRecord($scope, data);
        };

        $scope.copyProfile = function (profile) {
            var data = { copyFrom: profile };
            ModalFactory.editRecord($scope, data);
        };

        $scope.deleteProfile = function (id, name) {
            var data = { id: id, name: name };
            ModalFactory.deleteRecord($scope, data);
        };

        $scope.gotoPage = function (page) {
            ModalFactory.gotoPage($scope, page);
        };

        $scope.updatePagedItemCount = function (pageItemSelected) {
            ModalFactory.updatePagedItemCount($scope, pageItemSelected);
        };

        $scope.showPage = function (page, currentPage) {
            return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).visible[page - 1];
        };

        $scope.isFirstPageRange = function (page, currentPage) {
            return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isFirst;
        };

        $scope.isLastPageRange = function (page, currentPage) {
            return PagingFactory.getPageRange(page, currentPage, $scope.paging.pagedItemRange.value, $scope.paging.totalCount).isLast;
        };

        ModalFactory.initialize({
            scope: $scope,
            paging: PagingFactory.paging({ sortBy: "address" }),
            edit: {
                templateUrl: settingsDownloadProfilesEditModalTemplate,
                controller: "DownloadProfileEditModalCtrl"
            },
            remove: {
                templateUrl: settingsDownloadProfilesDeleteModalTemplate,
                controller: "DownloadProfileDeleteModalCtrl"
            },
            service: {
                instance: DownloadProfilesService,
                method: "getDownloadProfiles",
                response: "profiles",
                callback: function (data) {
                    $scope.profiles = data;
                }
            }
        });

        var query = $state.params.request;
        if (query) {
            ModalFactory.editRecord($scope, { request: query });
        }
    }
]);
