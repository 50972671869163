AccountProfileService.$inject = ["$http"];
export function AccountProfileService($http) {
    return {
        activate: activate,
        changePassword: changePassword,
        validateToken: validateToken,
        resendInvitation: resendInvitation
    };

    function changePassword(model) {
        return $http.put("/api/account/password", model).then((res) => res);
    }

    function activate(model) {
        return $http.post("/api/account/activate", model).then((res) => res);
    }

    function validateToken(token) {
        return $http.get(`/api/account/validatetoken/${token}`).then((res) => res);
    }

    function resendInvitation(token) {
        return $http.post(`/api/account/resend/invitation/${token}`).then((res) => res);
    }
}
