angular.module("kno2.shared.settings").controller("ReleaseTypesDeleteModalCtrl", [
    "$scope",
    "$uibModalInstance",
    "data",
    "ReleaseTypesService",
    "NotificationService",
    function ($scope, $uibModalInstance, data, ReleaseTypesService, NotificationService) {
        $scope.setForm = function (form) {
            $scope.form = form;
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };
        $scope.ok = function () {
            ReleaseTypesService.deleteReleaseType($scope.releaseType.id).then(
                function (response) {
                    NotificationService.success("The release type was deleted successfully.");
                    $uibModalInstance.close($scope.releaseType);
                },
                function (response) {
                    NotificationService.serverError(response.data.modelState, "There was an error deleting this release type.");
                }
            );
        };
        ReleaseTypesService.getReleaseType(data.id).then(function (data) {
            $scope.releaseType = data;
        });
    }
]);
