import deleteMessageModalTemplate from "../../common/delete-message/delete-message-modal.html";
import intakeMessageExportMessageModalTemplate from "./intake.message.export-message-modal.html";
import intakeMessageForwardMessageModalTemplate from "./intake.message.forward-message-modal.html";
import intakeAuditMessageModalTemplate from "../intake.audit-message-modal.html";

import { extend } from "lodash";
import angular from "angular";

IntakeMessageCtrl.$inject = [
    "$scope",
    "$stateParams",
    "$uibModal",
    "$location",
    "$window",
    "IntakeService",
    "MessageService",
    "IntegrationsService",
    "NotificationService",
    "SessionService",
    "MessageFactory",
    "AttachmentsFactory",
    "$timeout",
    "SubscriptionService",
    "TriageHttpService",
    "MessageHubService",
    "DownloadStatusService",
    "EventService",
    "FeatureService",
    "_",
    "message",
    "interoperabilitySettings",
    "ConversationServices",
    "$state",
    "UserPreferencesService",
    "appConfig"
];

export function IntakeMessageCtrl(
    $scope,
    $stateParams,
    $uibModal,
    $location,
    $window,
    IntakeService,
    MessageService,
    IntegrationsService,
    NotificationService,
    SessionService,
    MessageFactory,
    AttachmentsFactory,
    $timeout,
    SubscriptionService,
    TriageHttpService,
    MessageHubService,
    DownloadStatusService,
    EventService,
    FeatureService,
    _,
    message,
    interoperabilitySettings,
    ConversationServices,
    $state,
    UserPreferencesService,
    appConfig
) {
    var messageId = $stateParams.messageId;
    let onSave = () => {};

    MessageHubService.openMessage(messageId);

    $scope.brandDisplayName = appConfig.brandDisplayName;

    $scope.$on("$destroy", function () {
        MessageHubService.closeMessage(messageId);
        angular.element($window).off("unload.intakeMessage");
    });

    angular.element($window).on("unload.intakeMessage", function () {
        MessageHubService.closeMessage(messageId);
    });

    $scope.validateMessageModel = function (incomingErrors) {
        var errors = incomingErrors || [];
        MessageFactory.validate($scope, errors, "intake");
        if (errors.length > 0) {
            NotificationService.error({
                messageTitle: "Please fix the following errors.",
                messages: errors
            });
        }
        $scope.patientValidationErrors = errors;
        return errors.length === 0;
    };

    $scope.onPatientAttachmentsInit = function (ctrl) {
        onSave = ctrl.onSave;
    };

    $scope.save = function () {
        $scope.saveInProgress = true;

        onSave();
        MessageService.savePatientAndAttachments(messageId, $scope.patient, $scope.attachments)
            .then(() => {
                NotificationService.success("Successfully saved all message data.");
                $scope.saveInProgress = false;
            })
            .catch((err) => {
                NotificationService.errorToaster({
                    messageTitle: "There were errrors saving all message data.",
                    messages: [err.data.message]
                });
                $scope.saveInProgress = false;
            });
    };

    $scope.deleteClick = function (message, event) {
        event.stopPropagation();
        const modal = $uibModal.open({
            templateUrl: deleteMessageModalTemplate,
            controller: "DeleteMessageModalCtrl",
            controllerAs: "$ctrl",
            resolve: {
                message: function () {
                    return message;
                }
            }
        });

        modal.result.then(
            function (o) {
                $location.path("/intake");
            },
            () => {}
        );
    };

    $scope.nonPatientExport = function () {
        $scope.export("file", false, true);
    };

    var nonPatientExportSources = ["Fax", "Triage"];
    $scope.canNonPatientExport = function () {
        return (
            $scope.interoperabilitySettings &&
            $scope.interoperabilitySettings.enableNonPatientDirectory &&
            nonPatientExportSources.indexOf($scope.doc.sourceType) > -1
        );
    };

    $scope.export = function (exportMode, sendToClient, nonPatient) {
        AttachmentsFactory.documentTypeRequired = !AttachmentsFactory.documentTypeRequired;
        $scope.interoperabilitySettings.nonPatientExport = nonPatient;
        NotificationService.hideErrors();

        if (exportMode === "file") {
            if (!$scope.validateMessageModel()) {
                return;
            }
        }

        var promise = MessageService.savePatientAndAttachments(messageId, $scope.patient, $scope.attachments);
        promise.then(function () {
            $scope.initBundleOptions(exportMode, sendToClient, nonPatient);
            $scope.showExportMessageModal(exportMode, sendToClient);
        });
    };

    $scope.validateDownloadOptions = function () {
        NotificationService.hideErrors();
        $scope.downloadValidationErrors = [];

        if (!$scope.exportOptions.includeMessageBody && !$scope.exportOptions.includeAttachments) {
            $scope.downloadValidationErrors.push("You must include either the message body or at least one attachment.");
        }

        var requiredFields = ["firstName", "lastName", "birthDate", "gender"];

        _.each($scope.patient, function (val, key) {
            if (_.indexOf(requiredFields, key) > -1) {
                if (val == null || val.length < 1) {
                    var prettyKey = key.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
                        return str.toUpperCase();
                    });
                    $scope.downloadValidationErrors.push(prettyKey + " is required.");
                }
            }
        });

        if ($scope.attachments.length > 0) {
            var validAttachments = true;
            _.each($scope.attachments, function (attachment) {
                if (attachment.attachmentMeta.documentType == null || attachment.attachmentMeta.documentDate == null) {
                    validAttachments = false;
                }
            });

            if (!validAttachments) {
                $scope.downloadValidationErrors.push("Document Type and Date required.");
            }
        }

        return $scope.downloadValidationErrors;
    };

    $scope.forwardToEmr = function () {
        $scope.exportOptions = {
            includeMessageBody: $scope.doc.attachments.length == 0,
            includeAttachments: true
        };

        $timeout(function () {
            NotificationService.hideErrors();

            if (!$scope.validateMessageModel($scope.validateDownloadOptions())) {
                return;
            } else {
                var promise = MessageService.savePatientAndAttachments(messageId, $scope.patient, $scope.attachments);
                promise.then(function () {
                    $scope.initBundleOptions("forward");
                    $scope.showForwardMessageModal();
                });
            }
        });
    };

    $scope.initBundleOptions = function (exportMode, sendToClient, nonPatient) {
        $scope.bundleOptions = {
            messageId: messageId,
            exportMode: exportMode,
            attachments: [],
            sendToClient: sendToClient,
            nonPatient: nonPatient
        };
    };

    $scope.showExportMessageModal = function (exportMode, sendToClient) {
        var modalInstance = $uibModal.open({
            windowClass: "message-print-modal modal-600",
            templateUrl: intakeMessageExportMessageModalTemplate,
            controller: "ExportMessageModalCtrl",
            resolve: {
                data: function () {
                    return {
                        message: $scope.message,
                        bundleOptions: $scope.bundleOptions,
                        attachments: $scope.attachments,
                        interopSettings: $scope.interoperabilitySettings,
                        status: $scope.doc.status.toLowerCase()
                    };
                }
            }
        });

        modalInstance.result.then(
            function (result) {
                if (result.processed) {
                    $scope.doc.status = "Processed";
                    NotificationService.success("This message has been marked as processed.");
                    $scope.updateStateAfterProcessing();
                }
            },
            function () {
                //modal dismissed - noop
            }
        );
    };

    $scope.showForwardMessageModal = function () {
        $scope.message.patient = $scope.patient;

        $uibModal.open({
            windowClass: "modal-600",
            templateUrl: intakeMessageForwardMessageModalTemplate,
            controller: "ForwardMessageModalCtrl",
            resolve: {
                data: function () {
                    return {
                        message: $scope.message,
                        bundleOptions: $scope.bundleOptions,
                        attachments: $scope.attachments,
                        interopSettings: $scope.interoperabilitySettings
                    };
                }
            }
        });
    };

    $scope.canExportBundleToFile = function () {
        if ($scope.interoperabilitySettings) {
            return !$scope.interoperabilitySettings.isPrintExportMode && $scope.interoperabilitySettings.exportDirectory;
        }
        return false;
    };

    $scope.$on("$destroy", function () {
        DownloadStatusService.stopPoller();
    });

    $scope.download = {
        getMessage: function (message) {
            return message || $scope.doc;
        },

        isWaiting: function (message) {
            var doc = this.getMessage(message);
            return DownloadStatusService.isWaiting(doc);
        },

        isPending: function (message) {
            var doc = this.getMessage(message);
            return DownloadStatusService.messageDownloadIsPending(doc);
        },

        isDownloaded: function (message) {
            var doc = this.getMessage(message);
            return DownloadStatusService.messageDownloadIsDownloaded(doc);
        },

        isAvailable: function (message) {
            var doc = this.getMessage(message);
            return DownloadStatusService.messageDownloadIsAvailable(doc);
        },

        isNotAvailable: function (message) {
            var doc = this.getMessage(message);
            return DownloadStatusService.messageDownloadIsNotAvailable(doc);
        },

        getState: function (message, property) {
            return DownloadStatusService.getMessageDownloadState(message, property);
        },

        canMarkReadyForDownload: function () {
            var statuses = ["processed", "received", "forwarded", "replied", "suspended", "uploaded", "triaged"];
            return _.includes(statuses, $scope.doc.status.toLowerCase()) && !this.isWaiting() && !this.isPending() && !this.isDownloaded();
        },

        markAsDownloadable: async function () {
            try {
                if (this.canMarkReadyForDownload()) {
                    DownloadStatusService.setIsWaiting(true);

                    if ($scope.attachmentsForm.$invalid) {
                        $scope.invalidEmrDownload = true;
                        const invalid = _.filter($scope.attachments, (x, i) => $scope.attachmentsForm[i].$invalid);
                        NotificationService.error(`"${invalid[0].fileName}" has failed validation. Please review and try again.`);
                        DownloadStatusService.setIsWaiting(false);
                        return;
                    }

                    await MessageService.savePatientAndAttachments(messageId, $scope.patient, $scope.attachments);
                    await MessageService.waitForTransforms(messageId);
                    await MessageService.saveProcessedMessage(messageId, true, "", "awaitingemrexport");
                    const message = await MessageService.getMessage($scope.doc.id);

                    $scope.doc = message;
                    NotificationService.success("Message marked as available for download.");
                    DownloadStatusService.startPoller(messageId, onDownloadStatusPoll);
                    $scope.updateStateAfterProcessing();
                }
            } catch (error) {
                if (error.data && error.data.message) NotificationService.errorToaster(error.data.message);
                else NotificationService.errorToaster(`Error marking message available for download: ${error}`);
            }

            DownloadStatusService.setIsWaiting(false);
        }
    };

    function onDownloadStatusPoll(message) {
        $scope.doc = message;
    }

    $scope.onPatientAttachmentsInit = function (ctrl) {
        $scope.attachmentsForm = ctrl.attachmentsForm;
    };

    $scope.canDownloadMessageOption = function () {
        if ($scope.interoperabilitySettings) {
            return $scope.interoperabilitySettings.downloadMessage;
        }
        return false;
    };

    $scope.canForwardToEmr = function () {
        if ($scope.interoperabilitySettings) {
            return $scope.interoperabilitySettings.exportMode == "emr" && $scope.interoperabilitySettings.directAddress;
        }
        return false;
    };

    $scope.forwardClick = function (d, ev) {
        if (!SubscriptionService.checkAndShowPrompt("replyAndForwardButton")) $location.path("/release/forward/" + d.id).search({ returnUrl: "/intake" });
    };

    $scope.markAsProcessedClick = function () {
        var promise = MessageService.savePatientAndAttachments(messageId, $scope.patient, $scope.attachments);

        promise.then(function () {
            MessageService.saveProcessedMessage($scope.doc.id, true, "", "ForceProcess").then(function () {
                // fresh the message and requst the poller to start
                MessageService.getMessage($scope.doc.id).then(function (message) {
                    $scope.doc = message;
                    NotificationService.success("Message marked as processed.");
                    DownloadStatusService.startPoller($scope.doc.id, onDownloadStatusPoll);

                    $scope.updateStateAfterProcessing();
                });
            });
        });
    };

    $scope.updateStateAfterProcessing = () => {
        const preference = UserPreferencesService.getUserPreferences().afterProcessMessage;

        switch (preference) {
            case "ReturnToIntake":
                $state.go("intake");
                break;

            case "GoToNextMessage": // -DB - Postponing this feature for clarification - https://inofile.atlassian.net/browse/KEW-104
            case "DoNothing":
            default:
                break;
        }
    };

    $scope.replyClick = function (d, ev) {
        if (!SubscriptionService.checkAndShowPrompt("replyAndForwardButton")) $location.path("/release/reply/" + d.id).search({ returnUrl: "/intake" });
    };

    $scope.choose360xReferralClick = function (status, doc, message) {
        $scope.$applyAsync(function () {
            ConversationServices.choose360xReferral(status, doc.conversation.conversationId, message.id)
                .then(function () {
                    NotificationService.success(`The message has been successfully ${status}.`);
                    $scope.is360xReferralSelected = true;
                })
                .catch((err) => NotificationService.error(`Unable to change status to ${status}.`));
        });
    };

    function display360xTrackedNotifications() {
        if ($scope.doc.conversation.conversationId != null && $scope.doc.conversation.type == "360x") {
            $scope.notifications.push(
                {
                    level: "info",
                    message: "This message is part of a tracked referral"
                },
                {
                    level: "info",
                    message: `This referral has been ${$scope.doc.conversation.conversationStatus}`
                }
            );
        }
    }

    $scope.triageClick = function (message) {
        if (!SubscriptionService.checkAndShowPrompt("triageRoute")) TriageHttpService.triageAndShow(message);
    };

    $scope.showActivity = function (d, ev) {
        $uibModal.open({
            windowClass: "audit-message-modal",
            templateUrl: intakeAuditMessageModalTemplate,
            controller: "AuditMessageModalCtrl",
            controllerAs: "$ctrl",
            resolve: {
                message: function () {
                    return d;
                }
            }
        });
    };

    function updatePatient(meta, patient) {
        if (!meta || (!meta.firstName && !meta.lastName)) return;

        meta = _.pick(meta, "firstName", "lastName", "middleName", "birthDate", "gender");

        if (!patient.firstName && !patient.lastName) {
            angular.extend(patient, meta);
        }
    }

    $scope.toggleViewerPrintAndDownload = function (attachment) {
        const printHidden = $scope.viewerOptions.print === "hidden";
        const downloadHidden = $scope.viewerOptions.download === "hidden";
        if (printHidden && downloadHidden) return;

        const transforms = attachment.transforms || attachment.$$transforms || {};
        const hasTransforms = Object.keys(transforms).length;

        $scope.viewerOptions = extend({}, $scope.viewerOptions, {
            print: printHidden ? "hidden" : hasTransforms ? "disabled" : "enabled",
            download: downloadHidden ? "hidden" : hasTransforms ? "disabled" : "enabled"
        });
    };

    var initPageControls = function () {
        // Header title controls
        $scope.message = {
            id: messageId,
            subject: $scope.doc.subject || "",
            from: $scope.doc.fromAddress || "",
            to: $scope.doc.toAddress || "",
            systemReceivedDate: $scope.doc.messageDate || "",
            body: $scope.doc.body || ""
        };

        $scope.sourceType = $scope.doc.sourceType;

        // Patient Details data
        // Get this data from a service.
        // When saving this data, it will get saved to the server via the Messaging Services.
        $scope.patient = $scope.doc.patient || {};

        // Attachments data
        $scope.attachments = $scope.doc.attachments || [];

        _.each($scope.attachments, function (a) {
            if (a.attachmentMeta && a.attachmentMeta.patient) {
                updatePatient(a.attachmentMeta.patient, $scope.patient);
            }
        });

        $scope.showTriage = _.some($scope.attachments, { isRestorable: false }) || _.some($scope.attachments, { isPreviewAvailable: true });

        // No deleting of attachments on this page
        $scope.hideDeleteAttachments = true;

        MessageFactory.initializeDateTypeSelections($scope.attachments);

        _.each($scope.attachments, function (item) {
            item.isSelected = true;
        });

        //conditionally start the poller
        var messageDownloadIsPending = DownloadStatusService.messageDownloadIsPending($scope.doc);
        if (messageDownloadIsPending) {
            DownloadStatusService.startPoller($scope.doc.id, onDownloadStatusPoll);
        }
    };

    $scope.showExportDropdown = function () {
        return angular.element(".export-with-client").length > 0 || angular.element(".export-with-desktop").length > 0;
    };

    (function () {
        IntegrationsService.getCapabilities().then((integrations) => {
            const canUpload = _.find(integrations.capabilities, {
                display: IntegrationsService.capabilities.DocumentUpload
            });
            if (canUpload) {
                DownloadStatusService.displayErrorIfPresent(message);
            }
        });

        // The URL must contain the messageId/documentId.
        // Redirect back to intake page if there is no current document to process.
        if (!messageId) {
            IntakeService.redirectToIntakeView();
            return;
        }

        if ($stateParams.attachmentId) {
            MessageService.setInitalAttachmentId($stateParams.attachmentId);
        }

        $scope.doc = message;
        $scope.profile = SessionService.getProfile();
        $scope.location = $location;
        $scope.notifications = [];
        $scope.is360xReferralSelected = false;

        $scope.interoperabilitySettings = interoperabilitySettings;

        FeatureService.load().then(function () {
            $scope.viewerOptions = {
                rotate: "enabled",
                remove: "enabled",
                print: FeatureService.isEnabled("PrintFromBrowser") && interoperabilitySettings.enablePrintFromBrowser ? "enabled" : "hidden",
                download: FeatureService.isEnabled("DownloadFromBrowser") && interoperabilitySettings.enableDownloadFromBrowser ? "enabled" : "hidden",
                zoom: "auto",
                // Old options
                supportPageRotation: true,
                supportPageRemoval: true,
                allowPrinting: FeatureService.isEnabled("PrintFromBrowser") && interoperabilitySettings.enablePrintFromBrowser
            };
        });

        MessageFactory.init($scope, "intake");

        $scope.isIntake = true;

        initPageControls();
        if ($scope.doc.conversation) {
            display360xTrackedNotifications();
        }

        EventService.raiseReadEvent(messageId);
        $timeout(function () {
            if ($scope.doc.status.toLowerCase() === "deleted") NotificationService.warning("This message has been deleted.");
        }, 2000);
    })();
}
