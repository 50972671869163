routeModalConfig.$inject = ["$transitions", "$state", "$urlMatcherFactory", "$uibModal", "RedirectService", "_"];

let states = [];

/**
 * Creates a direct link to a modal. The modal needs to be registered as a route and have `data: { modal: true }` set.
 */
export function routeModalConfig($transitions, $state, $urlMatcherFactory, $uibModal, RedirectService) {
    $transitions.onStart({}, (transition) => {
        const to = transition.to();
        if ((to.data && to.data.modal) || to.modal) {
            return $uibModal
                .open(to)
                .result.catch(angular.noop)
                .then(() => {
                    const path = RedirectService.redirect();
                    const state = getStateByPath(path);
                    return $state.target(state.name);
                });
        }
    });

    function getStates() {
        return $state.get().map((state) => {
            $urlMatcherFactory.compile(state.url);
            return {
                matcher: $urlMatcherFactory.compile(state.url),
                state: state
            };
        });
    }

    function getStateByPath(path) {
        if (!states.length) states = getStates();

        const match = _.find(states, (state) => state.matcher.exec(path));

        return (match && match.state) || null;
    }
}
