import { Location } from "@angular/common";
import { Component, effect, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { NavigationEnd, Router } from "@angular/router";

@Component({
    selector: "kno2-empty",
    standalone: true,
    imports: [],
    template: ``
})
export class EmptyComponent {
    private location = inject(Location);
    private router = inject(Router);
    private routerEvent = toSignal(this.router.events);

    public constructor() {
        effect(() => {
            if (this.routerEvent() instanceof NavigationEnd) {
                this.location.replaceState(this.router.url);
            }
        });
    }
}
