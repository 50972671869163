idleConfig.$inject = ["IdleProvider", "KeepaliveProvider"];

//idle (session timeout)
export function idleConfig(IdleProvider, KeepaliveProvider) {
    const profileJson = localStorage.getItem("kno2.ProfileData") || "{}";
    const profile = JSON.parse(profileJson);
    const timeout = profile.sessionTimeoutMinutes || 10;

    //Force idle duration to at least 5 seconds. Cannot be less than one.
    var idleDuration = timeout * 60 - 60;
    if (idleDuration <= 1) idleDuration = 5;
    const warningDuration = 60;

    IdleProvider.interrupt("click keydown");
    IdleProvider.idle(idleDuration);
    IdleProvider.timeout(warningDuration);

    KeepaliveProvider.interval(60); // every minute
    KeepaliveProvider.http("/api/ping");
}

idleRun.$inject = ["Idle"];

export function idleRun(Idle) {
    Idle.watch();
}
