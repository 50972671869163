(function () {
    "use strict";
    angular.module("kno2.services").service("ExportService", ExportService);

    ExportService.$inject = ["$q", "$window", "$rootScope", "$timeout", "TaskGearService", "_"];

    function ExportService($q, $window, $rootScope, $timeout, TaskGearService, _) {
        $rootScope.TaskGearServiceWorkerEnabled = false;

        var worker = null,
            promises = {};

        return {
            register: register,
            postMessage: postMessage
        };

        function register() {
            // Register Web Worker
            if (typeof Worker !== "undefined") {
                worker = new Worker(`${EXPORT_WORKER_PATH}`);

                $rootScope.TaskGearServiceWorkerEnabled = true;
                TaskGearService.loadGearTasks();

                worker.onmessage = function (e) {
                    var event = e.data,
                        options = {
                            id: event.context.id,
                            type: event.context.type,
                            content: event.context.message.subject,
                            date: event.context.date,
                            href: event.context.href,
                            worker: event.response,
                            message: event.context.message
                        },
                        promise = promises[event.context.$$id];

                    // Worker response type
                    switch (event.response.type) {
                        case "success":
                            // Save downloaded bundle
                            var fileName = event.context.message.id + ".kno2";

                            try {
                                $window.saveAs(event.blob, fileName);
                            } catch (err) {
                                console.error(err);
                                promise.reject("An error occured while exporting. Please contact support.");
                            }

                            TaskGearService.notify(options);
                            promise.resolve();
                            break;
                        case "accepted":
                            // Notify its been sent to desktop client
                            TaskGearService.notify(options);
                            promise.resolve();
                            break;
                        case "error":
                            var message = "An error occured while exporting. Please contact support.";
                            if (_.isString(event.response.data)) {
                                try {
                                    event.response.data = JSON.parse(event.response.data);
                                } catch (e) {}
                            }
                            if (event.response.data && event.response.data.message) message = event.response.data.message;

                            console.error(e);
                            promise.reject(message);
                            break;
                        default:
                            promise.reject("An error occured while exporting. Please contact support.");
                            break;
                    }

                    delete promises[event.context.$$id];
                };
            }
        }

        function postMessage(event) {
            return $q(function (resolve, reject) {
                // If Web Worker supported, forward the message to the worker process.
                var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                if (isSafari && !event.sendToClient) {
                    reject("Your browser doesn't support exporting. Please try another browser.");
                } else if (worker) {
                    event.$$id = _.uniqueId();
                    promises[event.$$id] = { resolve: resolve, reject: reject };
                    worker.postMessage(event);
                } else {
                    reject("Your browser doesn't support exporting. Please try another browser.");
                }
            });
        }
    }
})();
