import editProfileModalTemplate from "./account.profile.edit-profile-modal.html";

ProfileCtrl.$inject = ["$scope", "$uibModal", "usersFactory", "SessionService", "$rootScope"];
export function ProfileCtrl($scope, $uibModal, usersFactory, sessionService, $rootScope) {
    $scope.refreshUser = function () {
        var promise = sessionService.refreshProfile();
        promise.then(function (data) {
            $scope.sessionUser = data;
        });
    };

    $scope.sessionUser = sessionService.getProfile();
    $scope.logoff = function () {
        $rootScope.logoff();
    };

    $scope.resetPassword = function () {
        var user = usersFactory.user(sessionService.getProfile().userId);
        user.then(function (response) {
            $uibModal
                .open({
                    templateUrl: editProfileModalTemplate,
                    controller: "ResetPasswordModalCtrl",
                    resolve: {
                        user: function () {
                            return response.data;
                        }
                    }
                })
                .result.then(
                    function (success) {},
                    function (error) {}
                );
        });
    };

    $scope.editProfile = function (changingPassword) {
        const modalInstance = $uibModal.open({
            templateUrl: editProfileModalTemplate,
            windowClass: "edit-profile-modal",
            controller: "EditProfileModalCtrl",
            controllerAs: "$ctrl",
            resolve: {
                userId: function () {
                    return $scope.sessionUser.userId;
                },
                showNotificationsTab: function () {
                    return false;
                },
                changingPassword: () => changingPassword
            }
        });

        modalInstance.result.then(
            function (success) {},
            function (error) {}
        );
    };
}

SettingsMenuCtrl.$inject = ["$scope", "SessionService", "CommonData"];
export function SettingsMenuCtrl($scope, SessionService, commonData) {
    $scope.currentUser = SessionService.getProfile();
    $scope.isLicensedOrg = function () {
        return $scope.currentUser.resellerType === commonData.resellerTypes.findByValue("Licensing").value;
    };
}
