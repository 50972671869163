// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/content/images/ajax-loader.gif");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div ng-if=\"!$ctrl.isProfileInit\" class=\"col-sm-12\"> <div class=\"pad-20 text-center\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> </div> <div ng-show=\"$ctrl.isProfileInit\" class=\"form-group row mb-4\"> <div class=\"col-sm-12\"> <label for=\"fileNamePreview\" class=\"control-label\"> Filename Preview </label> <input name=\"fileNamePreview\" class=\"form-control\" type=\"text\" disabled=\"disabled\" value=\"{{ $ctrl.selectedFilenameFields.length ? ($ctrl.selectedFilenameFields | downloadProfileFilename) + '.ext' : 'Select some fields' }}\"/> </div> </div> <div ng-show=\"$ctrl.isProfileInit\" class=\"form-group row\"> <div class=\"col-sm-6\"> <div class=\"draggable-item-header\"> <span style=\"font-weight:700\">Unused</span> </div> <div id=\"AvailableFilenameFields\" class=\"draggable-item-container\"> <div class=\"draggable-item\" data-key=\"{{ field }}\" ng-repeat=\"field in $ctrl.availableFilenameFields\"> {{ field | friendlyKeyName }} <span class=\"fa fa-fw fa-plus pull-right\" ng-click=\"$ctrl.addField(field)\"></span> </div> </div> </div> <div class=\"col-sm-6\"> <div class=\"draggable-item-header\"> <span style=\"font-weight:700\">Selected</span> </div> <div id=\"SelectedFilenameFields\" class=\"draggable-item-container\" ng-class=\"{ empty: !$ctrl.selectedFilenameFields || !$ctrl.selectedFilenameFields.length }\"> <div class=\"draggable-item\" data-key=\"{{ field }}\" ng-repeat=\"field in $ctrl.selectedFilenameFields\"> <span> <i class=\"fa fa-sort mr-2\"></i> {{ $index + 1 }}. </span> {{ field | friendlyKeyName }} <span class=\"fa fa-fw fa-trash pull-right\" ng-click=\"$ctrl.removeField(field)\"></span> </div> </div> </div> </div> ";
// Exports
var _module_exports = code;;
var path = 'App/src/settings/download-profiles/filename-options/filename-options.component.html';
window.angular.module('kno2.templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;