import { Component, input } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";

@Component({
    selector: "kno2-form-select",
    standalone: true,
    imports: [ReactiveFormsModule],
    templateUrl: "./form-select.component.html",
    styles: `
            .select-container {
                position: relative;
                display: inline-block;
                width: 100%;
            }

            .select-container .caret {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                border-top-color: #999;
                border-bottom-color: #999;
            }

            select {
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                padding-right: 2rem;
                width: 100%;
            }
        `
})
export class FormSelectComponent {
    public control = input.required<FormControl>();
    public options = input<[string, any][]>([]);
    public hasError = input<boolean>(false);
    public placeholder = input<string>();
}
