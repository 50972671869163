import { Component } from "@angular/core";

@Component({
    selector: "kno2-loading-spinner",
    template: `
        <div class="d-flex justify-content-center">
            <div
                class="spinner-border text-primary"
                role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    `,
    standalone: true
})
export class LoadingSpinnerComponent {}
