<div class="modal-header">
    <button
        (click)="activeModal.close()"
        type="button"
        class="close"
        aria-hidden="true">
        &times;
    </button>
    <h2 class="modal-title">Configure Directory Entry</h2>
</div>
<div class="modal-body px-4">
    <form
        id="directoryEntryForm"
        #directoryEntryForm="ngForm"
        class="row"
        name="editDirectoryEntryForm"
        role="form">
        <div class="col-6">
            <div class="form-group row">
                <label
                    for="DirectoryOid"
                    class="col-3 control-label text-right col-form-label px-0">
                    Directory OID
                </label>
                <div class="col-9">
                    <div class="input-group">
                        <input
                            [(ngModel)]="directoryEntry().id"
                            type="text"
                            class="form-control"
                            name="DirectoryOid"
                            placeholder="Directory OID"
                            [readonly]="!editOid()"
                            required />
                        <div class="input-group-append">
                            <a
                                href="#"
                                title="Edit"
                                class="btn"
                                (click)="editOid.set(true)">
                                <i class="fa fa-pencil"></i>
                            </a>
                        </div>
                        <div class="invalid-feedback">Directory OID is required.</div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="HomeCommunityId"
                    class="col-3 control-label text-right col-form-label px-0">
                    HomeCommunityId
                </label>
                <div class="col-9">
                    <input
                        [(ngModel)]="directoryEntry().homeCommunityId"
                        type="text"
                        class="form-control"
                        name="HomeCommunityId"
                        placeholder="HomeCommunityId" />
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="DisplayName"
                    class="col-3 control-label text-right col-form-label px-0">
                    Display Name
                </label>
                <div class="col-9">
                    <input
                        [(ngModel)]="directoryEntry().name"
                        type="text"
                        class="form-control"
                        name="DisplayName"
                        placeholder="Display Name"
                        required />
                    <div class="invalid-feedback">Display name is required.</div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="LegalName"
                    class="col-3 control-label text-right col-form-label px-0">
                    Legal Name
                </label>
                <div class="col-9">
                    <input
                        [(ngModel)]="directoryEntry().name"
                        type="text"
                        class="form-control"
                        name="LegalName"
                        placeholder="Legal Name" />
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="OtherName"
                    class="col-3 control-label text-right col-form-label px-0">
                    Other Name
                </label>
                <div class="col-9">
                    <input
                        [(ngModel)]="directoryEntry().name"
                        type="text"
                        class="form-control"
                        name="OtherName"
                        placeholder="Other Name" />
                </div>
            </div>
            @if (directoryEntry().directoryEntryType !== DirectoryEntryType.Participant) {
                <div class="form-group row">
                    <label
                        for="ParentDirectoryOid"
                        class="col-3 control-label text-right col-form-label px-0">
                        Parent Directory OID
                    </label>
                    <div class="col-9">
                        <input
                            [(ngModel)]="directoryEntry().parentId"
                            #parentDirectoryOidFormControl="ngModel"
                            type="text"
                            class="form-control"
                            name="ParentDirectoryOid"
                            placeholder="Parent Directory OID" />
                        <div class="invalid-feedback">A parent OID is required for child entries.</div>
                    </div>
                </div>
            }
            <div class="form-group row">
                <label
                    for="purposeOfUseCode"
                    class="col-3 control-label text-right col-form-label px-0">
                    Purpose of Use Codes
                </label>
                <div class="col-9">
                    <select
                        [(ngModel)]="directoryEntry().purposeOfUseCodes"
                        size="8"
                        multiple
                        required
                        class="form-control"
                        name="purposeOfUseCodes">
                        @for (purposeOfUse of purposesOfUse(); track $index) {
                            <option [value]="purposeOfUse.code">{{ purposeOfUse.description }}</option>
                        }
                    </select>
                    <div class="invalid-feedback">At least one purpose of use code is required.</div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group row">
                <label
                    for="Address"
                    class="col-3 control-label text-right col-form-label px-0">
                    Street
                </label>
                <div class="col-9">
                    <input
                        [(ngModel)]="directoryEntryOrganizationAddress().street1"
                        type="text"
                        class="form-control"
                        name="Address"
                        placeholder="Street"
                        required />
                </div>
                <div class="invalid-feedback">Street is required.</div>
            </div>
            <div class="form-group row">
                <label
                    for="City"
                    class="col-3 control-label text-right col-form-label px-0">
                    City
                </label>
                <div class="col-9">
                    <input
                        [(ngModel)]="directoryEntryOrganizationAddress().city"
                        type="text"
                        class="form-control"
                        name="City"
                        placeholder="City"
                        required />
                    <div class="invalid-feedback">City is required.</div>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="State"
                    class="col-3 control-label text-right col-form-label px-0">
                    State
                </label>
                <div class="col-9">
                    <select
                        [(ngModel)]="directoryEntryOrganizationAddress().state"
                        class="form-control"
                        name="State"
                        required>
                        @for (state of commonData.states.asArray(); track $index) {
                            <option [value]="state.abbr">{{ state.name }}</option>
                        }
                    </select>
                </div>
                <div class="invalid-feedback">State is required.</div>
            </div>
            <div class="form-group row">
                <label
                    for="Zip"
                    class="col-3 control-label text-right col-form-label px-0">
                    Zip
                </label>
                <div class="col-9">
                    <input
                        [(ngModel)]="directoryEntryOrganizationAddress().zipCode"
                        type="text"
                        class="form-control"
                        name="Zip"
                        placeholder="Zip"
                        required />
                </div>
                <div class="invalid-feedback">Zip is required.</div>
            </div>
            <div class="form-group row">
                <label
                    for="Country"
                    class="col-3 control-label text-right col-form-label px-0">
                    Country
                </label>
                <div class="col-9">
                    <input
                        [(ngModel)]="directoryEntryOrganizationAddress().country"
                        type="text"
                        class="form-control"
                        name="Country"
                        placeholder="Country"
                        required />
                </div>
                <div class="invalid-feedback">Country is required.</div>
            </div>
            <div class="form-group row">
                <label
                    for="InboundMessageAddress"
                    class="col-3 control-label text-right col-form-label px-0 mt-n2">
                    Inbound Message Kno2 Address
                </label>
                <div class="col-9">
                    <select
                        [(ngModel)]="directoryEntry().inboundMessageAddress"
                        class="form-control"
                        name="InboundMessageAddress">
                        @if (!directoryEntry().inboundMessageAddress) {
                            <option hidden>Select an inbound address</option>
                        }
                        @for (documentSource of documentSources(); track $index) {
                            <option [selected]="documentSource === directoryEntry().inboundMessageAddress">{{ documentSource }}</option>
                        }
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label
                    for="IsActive"
                    class="col-3 text-right px-0">
                    Is Active
                </label>
                <div class="col-9">
                    <input
                        disabled
                        [checked]="directoryEntry().isActive"
                        type="checkbox"
                        name="IsActive" />
                </div>
            </div>
            <!-- <div class="form-group row">
                <label
                    for="ActivationDate"
                    class="col-3 control-label text-right col-form-label px-0">
                    Registry Activation Date
                </label>
                <div class="col-9">
                    <input
                        readonly
                        [value]="directoryEntry().created | date: 'MMM d, y'"
                        type="text"
                        class="form-control"
                        name="ActivationDate" />
                </div>
            </div> -->
        </div>
    </form>
</div>
<div class="col-12 modal-footer">
    <button
        (click)="activeModal.close()"
        type="button"
        class="btn btn-default">
        Cancel
    </button>
    <button
        form="directoryEntryForm"
        (click)="directoryEntryForm.control.markAllAsTouched() || (directoryEntryForm.valid && saveDirectoryEntry())"
        type="submit"
        class="btn btn-primary">
        <span>Save</span>
    </button>
</div>
