// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/content/images/chrome_128x128.png");
var ___HTML_LOADER_IMPORT_1___ = require("/content/images/firefox_128x128.png");
var ___HTML_LOADER_IMPORT_2___ = require("/content/images/microsoft-edge-icon-128x128.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div style=\"height:100vh;background-color:#fff;padding-top:100px\"> <h1 class=\"text-center\">Unsupported Browser.</h1> <h2 class=\"text-center\">We recommend the latest version of Chrome, Firefox or Microsoft Edge to use {{ brandDisplayName }}.</h2> <div class=\"text-center\"> <a href=\"http://www.google.com/chrome/intl/en/dl/download.html\" target=\"_blank\" rel=\"noopener noreferrer\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </a> <a href=\"https://www.mozilla.org/en-US/firefox/new/\" target=\"_blank\" rel=\"noopener noreferrer\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> </a> <a href=\"https://www.microsoft.com/en-us/edge/download\" target=\"_blank\" rel=\"noopener noreferrer\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"/> </a> </div> </div> ";
// Exports
var _module_exports = code;;
var path = 'App/src/common/unsupported-browser/unsupported-browser.html';
window.angular.module('kno2.templates').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;