import { Injectable } from "@angular/core";
import { Route } from "@angular/router";
import { routes } from "../../app.routes";

@Injectable({
    providedIn: "root"
})
export class RouteRegistryService {
    private registeredRoutes = [];

    constructor() {
        this.registeredRoutes = this.setRegisteredRoutes(routes);
    }

    public hasRoute(route: string): boolean {
        return this.registeredRoutes.includes(route);
    }

    private setRegisteredRoutes(routes: Route[], parentPath?: string): string[] {
        return routes.reduce((acc, route) => {
            const path = parentPath ? `${parentPath}/${route.path}` : route.path;
            if (route.children) {
                return [...acc, ...this.setRegisteredRoutes(route.children, `${path}`)];
            }
            return [...acc, `/${path}`];
        }, []);
    }
}
