<div class="row">
    <div class="col-sm-12">
        <table class="table table-striped table-bordered bootstrap-datatable datatable actions-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Directory Oid</th>
                    <th>Entity Type</th>
                    <th>Parent Directory Oid</th>
                    <!-- <th>Activation Date</th> -->
                    <th style="width: 50px">Edit</th>
                </tr>
            </thead>
            <tbody>
                @for (directoryEntry of allDirectoryEntries(); track $index) {
                    <tr>
                        <td class="valign-middle">{{ directoryEntry.name }}</td>
                        <td class="valign-middle">{{ directoryEntry.id }}</td>
                        <td class="valign-middle">{{ directoryEntry.directoryEntryType | titlecase }}</td>
                        <td class="valign-middle">{{ directoryEntry.parentId }}</td>
                        <!-- <td class="valign-middle">{{ directoryEntry.created | date: "MMM d, y" }}</td> -->

                        <td class="actions-table-actions-cell">
                            <a
                                href="#"
                                title="Edit"
                                (click)="showQhinDetailsModal(directoryEntry)"
                                class="btn">
                                <i class="fa fa-pencil"></i>
                            </a>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
        <div class="dropdown pull-right">
            <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false">
                <span>Add</span>
                <i class="ml-2 fa fa-plus"></i>
            </button>
            <div class="dropdown-menu">
                <button
                    type="button"
                    [class.disabled]="directoryEntry()"
                    (click)="showQhinDetailsModal({ directoryEntryType: DirectoryEntryType.Participant })"
                    class="dropdown-item"
                    href="#">
                    Participant
                    <i
                        data-toggle="tooltip"
                        title="To the extent permitted by applicable SOP(s), a U.S. Entity that has entered into the ToP in a legally binding contract with a QHIN to use the QHIN’s Designated Network Services to participate in TEFCA Exchange in compliance with the ToP (FROM Common Agreement)."
                        class="ml-2 fa fa-info-circle"></i>
                </button>
                <button
                    type="button"
                    (click)="showQhinDetailsModal({ directoryEntryType: DirectoryEntryType.Subparticipant })"
                    class="dropdown-item"
                    href="#">
                    Sub-Participant
                    <i
                        data-toggle="tooltip"
                        title="To the extent permitted by applicable SOP(s), a U.S. Entity that has entered into the ToP in a legally binding contract with a Participant or another Sub-Participant to use the Participant’s or Sub-participant’s Connectivity Services to participate in TEFCA Exchange in compliance with the ToP (FROM Common Agreement)."
                        class="ml-2 fa fa-info-circle"></i>
                </button>
                <button
                    type="button"
                    (click)="showQhinDetailsModal({ directoryEntryType: DirectoryEntryType.Child })"
                    class="dropdown-item"
                    href="#">
                    Child
                    <i
                        data-toggle="tooltip"
                        title="The stated difference between a Sub-Participant and a Child is that the Sub-Participant is a 'legal' entity that has signed the QHIN Contract, and a Child has not signed the QHIN Contract."
                        class="ml-2 fa fa-info-circle"></i>
                </button>
            </div>
        </div>
    </div>
</div>
