import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";

export const roleGuard: CanActivateFn = (route) => {
    const router = inject(Router);
    const upgrade = inject(UpgradeModule);
    const sessionService = upgrade.$injector.get("SessionService");

    const canActivate = sessionService.userInRole(route.data?.roles);

    if (canActivate) return true;

    router.navigateByUrl("/unauthorized");
    return false;
};
