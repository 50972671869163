import unauthorizedTemplateUrl from "../common/unauthorized/unauthorized.html";
import unsupportedBrowserTemplateUrl from "../common/unsupported-browser/unsupported-browser.html";
import errorLayoutUrl from "../layouts/error.layout.html";

routeConfig.$inject = ["$locationProvider", "$stateProvider", "$urlRouterProvider", "$urlMatcherFactoryProvider"];

export function routeConfig($locationProvider, $stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider) {
    $locationProvider.html5Mode(true);
    $locationProvider.hashPrefix("");
    $urlMatcherFactoryProvider.caseInsensitive(true);
    $urlMatcherFactoryProvider.strictMode(false);

    $stateProvider
        .state("main", {
            url: "",
            redirectTo: "login",
            resolve: {
                init: [
                    "$q",
                    "AppConfigService",
                    ($q, appConfigService) => {
                        const deferred = $q.defer();

                        appConfigService.load().then(() => deferred.resolve());

                        return deferred.promise;
                    }
                ]
            }
        })
        .state("login", {
            url: "/account/login",
            onEnter: [
                "Auth0Service",
                "LocalStorageFactory",
                (Auth0Service, LocalStorageFactory) => {
                    const loginType = LocalStorageFactory.get("loginType");
                    Auth0Service.login(loginType);
                }
            ]
        })
        .state("loginType", {
            url: "/account/login/:type",
            onEnter: [
                "Auth0Service",
                "$stateParams",
                "LocalStorageFactory",
                (Auth0Service, $stateParams, LocalStorageFactory) => {
                    LocalStorageFactory.set("loginType", $stateParams.type);
                    Auth0Service.login($stateParams.type);
                }
            ]
        })
        .state("ssologout", {
            url: "/account/logout",
            component: "auth0Logout"
        })
        .state("renewtokens", {
            abstract: true,
            parent: "main",
            resolve: {
                init: [
                    "Auth0Service",
                    "FeatureService",
                    "SyncService",
                    "AlertsNotificationService",
                    "UserPreferencesService",
                    "BrandSettingsService",
                    "SupportedFileExtensionsService",
                    "SessionService",
                    "IntercomService",
                    (
                        Auth0Service,
                        FeatureService,
                        SyncService,
                        AlertsNotificationService,
                        UserPreferencesService,
                        BrandSettingsService,
                        SupportedFileExtensionsService,
                        SessionService,
                        IntercomService
                    ) => {
                        if (!Auth0Service.getAccessToken()) {
                            return Auth0Service.renewTokens()
                                .then(() =>
                                    makeInitialApiCalls(
                                        SyncService,
                                        AlertsNotificationService,
                                        FeatureService,
                                        UserPreferencesService,
                                        BrandSettingsService,
                                        SupportedFileExtensionsService,
                                        SessionService,
                                        IntercomService
                                    )
                                )
                                .catch((err) => {
                                    console.log(err);
                                });
                        } else {
                            return makeInitialApiCalls(
                                SyncService,
                                AlertsNotificationService,
                                FeatureService,
                                UserPreferencesService,
                                BrandSettingsService,
                                SupportedFileExtensionsService,
                                SessionService,
                                IntercomService
                            );
                        }
                    }
                ]
            }
        })
        .state("unauthorized", {
            url: "/unauthorized",
            parent: "error",
            templateUrl: unauthorizedTemplateUrl
        })
        .state("unsupportedBrowser", {
            url: "/unsupported",
            templateUrl: unsupportedBrowserTemplateUrl
        })
        .state("home", {
            component: "homeLayout",
            parent: "renewtokens"
        })
        .state("error", {
            templateUrl: errorLayoutUrl,
            controller: "ErrorLayoutCtrl"
        })
        .state("otherwise", {
            url: "/{path:.*}",
            resolve: {
                checkPath: [
                    "$injector",
                    "$location",
                    "$timeout",
                    "RedirectService",
                    ($injector, $location, $timeout, RedirectService) => {
                        const path = $location.path();
                        const routeRegistryService = $injector.get("routeRegistryService");
                        const ngxRouter = $injector.get("ngxRouter");

                        const isNgxRoute = routeRegistryService.hasRoute(path);
                        if (isNgxRoute) {
                            ngxRouter.navigate([path]);
                        } else {
                            const redirectPath = RedirectService.redirect();
                            $timeout(() => $location.replace().path(redirectPath));
                        }
                    }
                ]
            },
            template: `
                <div ng-controller="SessionTimeoutCtrl"></div>
                <div ng-controller="CancelledOrgCtrl"></div>
            `
        });
}

routeRun.$inject = ["$transitions", "$state", "FeatureGuardFactory"];

export function routeRun($transitions, $state, FeatureGuardFactory) {
    var criteria = {
        to: function (state) {
            return state.redirectTo != null;
        }
    };

    $transitions.onBefore(criteria, function (transition) {
        var state = transition.to().redirectTo;
        return transition.router.stateService.target(state);
    });

    var criteria2 = {
        to: function (state) {
            return state.name != "callback";
        }
    };

    $transitions.onSuccess(criteria2, function () {
        FeatureGuardFactory.canActivate($state.current.url);
    });
}

function makeInitialApiCalls(
    SyncService,
    AlertsNotificationService,
    FeatureService,
    UserPreferencesService,
    BrandSettingsService,
    SupportedFileExtensionsService,
    SessionService,
    IntercomService
) {
    SyncService.register(AlertsNotificationService.refresh, 300);
    SupportedFileExtensionsService.load();
    FeatureService.load();
    BrandSettingsService.load();
    SessionService.refreshProfile();
    IntercomService.boot();
    return UserPreferencesService.load().then(() => true);
}
