angular.module("kno2.factories").factory("AuthInterceptorFactory", [
    "$rootScope",
    "$state",
    "$q",
    "NotificationService",
    "Auth0Service",
    function ($rootScope, $state, $q, NotificationService, Auth0Service) {
        return {
            request: function (config) {
                if (config.skipInterceptors) return config;
                var token = Auth0Service.getAccessToken();

                if (token) {
                    config.headers = config.headers || {};
                    config.headers.Authorization = "Bearer " + token;
                }
                return config;
            },
            responseError: function (rejection) {
                if (rejection.status === 401 || rejection.status === 403) {
                    if (rejection.data && rejection.data.reason === "Token revoked or expired.") {
                        $rootScope.logoff();
                    } else {
                        NotificationService.errorToaster("You are not authorized to view this resource.");
                        $state.go("unauthorized");
                    }
                }
                return $q.reject(rejection);
            }
        };
    }
]);
