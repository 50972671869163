// @ts-expect-error
import editProfileModalTemplate from "./account.profile.edit-profile-modal.html";

export class AccountEditProfileService {
    public static $inject: Array<string> = ["$uibModal"];

    constructor(private $uibModal) {}

    public editProfile(changingPassword: boolean, userId: string): void {
        const modalInstance = this.$uibModal.open({
            templateUrl: editProfileModalTemplate,
            windowClass: "edit-profile-modal",
            controller: "EditProfileModalCtrl",
            controllerAs: "$ctrl",
            resolve: {
                userId: () => userId,
                showNotificationsTab: () => false,
                changingPassword: () => changingPassword
            }
        });

        modalInstance.result.then(
            () => {},
            () => {}
        );
    }
}

AccountEditProfileService.$inject = ["$uibModal"];
