let ctrl;
declare const _;

class SignaturesController {
    public static $inject: Array<string> = [
        "LocalStorageFactory",
        "$interval",
        "$timeout",
        "MessageService",
        "EventService",
        "SessionService",
        "$confirm",
        "ReleaseService"
    ];

    public readonly defaultTab: string = "events";
    public readonly GET_SIGNATURES_RECENT_EVENTS_PAGE_KEY: string = "getSignatures.recentEventsPage";
    public readonly GET_SIGNATURES_DRAFTS_PAGE_KEY: string = "getSignatures.draftsPage";
    public readonly GET_SIGNATURES_ACTIVE_TABS_KEY: string = "getSignatures.activeTab";

    public draftTimer: any;
    public timer: any;

    constructor(
        private LocalStorageFactory,
        private $interval,
        private $timeout,
        private MessageService,
        private EventService,
        private SessionService,
        private $confirm,
        private ReleaseService
    ) {}

    public $onInit(): void {
        ctrl = this;
        ctrl.events = { items: [], pageSize: 10 };
        ctrl.drafts = { items: [], pageSize: 10 };

        ctrl.userIsAdmin = ctrl.SessionService.userInRole("Administrator");

        ctrl.release = {};
        ctrl.releaseTypes = null;

        ctrl.tabs = {
            drafts: { active: false },
            events: { active: false }
        };

        const activeTab = ctrl.isFirstRequestForView ? ctrl.defaultTab : ctrl.LocalStorageFactory.get(ctrl.GET_SIGNATURES_ACTIVE_TABS_KEY) || ctrl.defaultTab;
        ctrl.changeActiveTab(activeTab);

        ctrl.loadRecentEvents();
        ctrl.loadDrafts();
    }

    public changeActiveTab(tab): void {
        Object.keys(ctrl.tabs).forEach((t) => (ctrl.tabs[t].active = false));
        ctrl.tabs[tab].active = true;

        ctrl.LocalStorageFactory.set(ctrl.GET_SIGNATURES_ACTIVE_TABS_KEY, tab);
        ctrl.setIntervalForTab(tab);
    }

    public setIntervalForTab(tab): void {
        if (!tab || tab === "events") {
            if (ctrl.draftTimer) this.$interval.cancel(ctrl.draftTimer);
            if (!ctrl.timer || ctrl.timer.$$state.status === 2) ctrl.timer = ctrl.refreshEvents();
        }
        if (tab === "drafts") {
            if (ctrl.timer) this.$interval.cancel(ctrl.timer);
            if (!ctrl.draftTimer || ctrl.draftTimer.$$state.status === 2) ctrl.draftTimer = ctrl.refreshDrafts();
        }
    }

    public refreshEvents() {
        return ctrl.$interval(function () {
            ctrl.$timeout(ctrl.showEventsForCurrentPage, 100);
        }, 15000);
    }

    public showEventsForCurrentPage() {
        ctrl.LocalStorageFactory.set(ctrl.GET_SIGNATURES_RECENT_EVENTS_PAGE_KEY, ctrl.events.currentPage);

        ctrl.loadRecentEvents();
    }

    public loadRecentEvents() {
        const currentPage = ctrl.events.currentPage || 1;
        const promise = ctrl.EventService.getDocusignRecentEvents(10, currentPage, ctrl.filter);
        promise.then(function (response) {
            ctrl.events.items = response.data.items;
            ctrl.events.count = response.data.totalCount;
            _.each(ctrl.events.items, ctrl.attachWorkflowIdToEvent);
            return response;
        });
        return promise;
    }

    public refreshDrafts() {
        return ctrl.$interval(function () {
            ctrl.$timeout(ctrl.loadDrafts, 100);
        }, 15000);
    }

    public loadDrafts() {
        const promise = ctrl.MessageService.getSignatureDrafts({
            pageStart: ctrl.drafts.currentPage,
            pageSize: ctrl.drafts.pageSize
        });
        promise.then(function (response) {
            ctrl.LocalStorageFactory.set(ctrl.GET_SIGNATURES_DRAFTS_PAGE_KEY, ctrl.drafts.currentPage);
            ctrl.drafts.items = response.data.items;
            ctrl.drafts.count = response.data.totalCount;
        });
        return promise;
    }

    public deleteDraft(draftId: string): any {
        const modal = ctrl.$confirm.open({
            title: "Delete Draft",
            bodyText: `You are about to delete this draft.\n\nAre you sure?`
        });

        modal.result.then((ok) => {
            if (ok) {
                ctrl.ReleaseService.deleteDraft(draftId).then(() => {
                    ctrl.loadDrafts();
                });
            }
        });
    }

    public getSignersString(event: any): string {
        if (event.signers) {
            return event.signers.map((x) => (x.email ? x.email : x.hostEmail)).join(", ");
        }
        return "";
    }

    public $onDestroy() {
        if (ctrl.timer) {
            ctrl.$interval.cancel(ctrl.timer);
        }
        if (ctrl.draftTimer) {
            ctrl.$interval.cancel(ctrl.draftTimer);
        }
    }
}

class SignaturesComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./signatures.component.html");
        this.controller = SignaturesController;
        this.bindings = {};
    }
}

export const signaturesComponent = new SignaturesComponent();
