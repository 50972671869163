import { Component, Input, input } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";

@Component({
    selector: "kno2-form-input",
    standalone: true,
    imports: [ReactiveFormsModule, NgxMaskDirective],
    templateUrl: "./form-input.component.html",
    providers: [provideNgxMask()]
})
export class FormInputComponent {
    public control = input.required<FormControl>();
    public hasError = input<boolean>(false);
    public placeholder = input<string>();
    public mask = input<string>();
}
