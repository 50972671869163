angular.module("kno2.settings").controller("DownloadProfileEditModalCtrl", [
    "$scope",
    "$uibModalInstance",
    "DownloadProfilesService",
    "NotificationService",
    "data",
    "$timeout",
    function ($scope, $uibModalInstance, DownloadProfilesService, NotificationService, data, $timeout) {
        $scope.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        $scope.saveChanges = function () {
            if (!$scope.currentForm.$valid) {
                $scope.saving = false;
                return;
            }
            if (!$scope.useAdvancedOptionsEnabled) $scope.profile.filenameOptions = [];
            $scope.saving = true;
            DownloadProfilesService.saveProfile($scope.profile).then(
                function (results) {
                    NotificationService.success("Download Profile Saved");
                    $timeout(function () {
                        $uibModalInstance.close();
                    }, 500);
                },
                function (error) {
                    NotificationService.error(error.data.message);
                    $scope.saving = false;
                }
            );
        };

        var init = function () {
            $scope.isProfileInit = false;
            $scope.useAdvancedOptionsEnabled = false;
            $scope.profile = {
                filenameOptions: [],
                exportProfile: {
                    exportType: "Original"
                }
            };
            if (data.id) {
                DownloadProfilesService.getProfile(data.id).then(
                    function (results) {
                        $scope.profile = results;
                        if ($scope.profile.filenameOptions.length) $scope.useAdvancedOptionsEnabled = true;
                        $scope.isProfileInit = true;
                    },
                    function (error) {
                        NotificationService.error(error);
                    }
                );
            } else if (data.request) {
                $scope.isProfileInit = true;
                $scope.profile.query = data.request;
                $scope.profile.queryString = $.param(data.request);
                $scope.profile.query.properties = Object.getOwnPropertyNames(data.request).sort();
            } else if (data.copyFrom) {
                $scope.profile = {
                    name: data.copyFrom.name,
                    queryString: data.copyFrom.queryString,
                    isEnabled: false,
                    exportProfile: {
                        exportType: data.copyFrom.exportProfile.exportType,
                        folder: data.copyFrom.exportProfile.folder
                    }
                };
            }
            $scope.isProfileInit = true;
        };

        init();
    }
]);
