IntakeRulesCtrl.$inject = [
    "$scope",
    "$rootScope",
    "$q",
    "RulesService",
    "SessionService",
    "IntakeService",
    "SubscriptionService",
    "LocalStorageFactory",
    "_",
    "InteroperabilityService"
];

export function IntakeRulesCtrl($scope, $rootScope, $q, RulesService, SessionService, IntakeService, SubscriptionService, storage, _, InteroperabilityService) {
    $scope.viewStateMap = {
        views: [
            {
                name: "By Rules",
                value: "byRules",
                selected: false, // We set this to true if the user is != Admin
                statusGroup: null
            },
            {
                name: "All",
                value: "all",
                selected: true, // We set this to false if the user is != Admin
                statusGroup: null
            },
            {
                name: "Unassigned",
                value: "unassigned",
                selected: false,
                statusGroup: null
            }
        ]
    };

    $scope.sourcesStateMap = {
        sources: [
            {
                name: "Direct Messages",
                value: "DirectMessage",
                selected: true
            },
            {
                name: "Faxes",
                value: "Fax",
                selected: true
            },
            {
                name: "Triage",
                value: "Triage",
                selected: true
            },
            {
                name: "Carequality",
                value: "Carequality",
                selected: true
            },
            {
                name: "Ihde",
                value: "Ihde",
                selected: true
            }
        ]
    };

    $scope.onSourceClick = function (source) {
        fireRuleChangedEvent();
    };

    $scope.includeContentMap = {
        NaviHealth: false,
        Ihde: false
    };

    $scope.onIncludeContentClick = function () {
        fireRuleChangedEvent();
    };

    $scope.processedTypes = [
        { name: "Awaiting EMR Export", value: "AwaitingEMRExport", selected: true },
        { name: "Exported to CDA", value: "StructuredExport", selected: true },
        { name: "Exported to EMR", value: "EMRExported", selected: true },
        { name: "Exported as Original", value: "NativeExport", selected: true },
        { name: "Exported to PDF", value: "PDFExport", selected: true },
        { name: "Printed", value: "Printed", selected: true },
        { name: "Saved", value: "Saved", selected: true }
    ];

    var getDefaultViewStateMap = function () {
        var viewStateMap = $scope.viewStateMap;

        if (!$scope.userProfile.isAdmin) {
            // not admin, set the default radio button to "By Rules"
            _.each(viewStateMap.views, function (x) {
                return (x.selected = x.value == "byRules");
            });
        }
        _.each(viewStateMap.views, function (v) {
            v.statusGroup = _.flattenDeep(getStatusMap(v));
        });

        // Set the default statusNetworks for the Unassigned view.
        _.each(
            _.find(viewStateMap.views, function (x) {
                return x.value == "unassigned";
            }).statusGroup,
            function (n) {
                if (n.name == "In Progress") {
                    n.selected = true;
                }
            }
        );

        return viewStateMap;
    };

    var getStatusMap = function (view) {
        // Returns a statusMap filtered to the passed in view.
        var statusMap = {
            statusGroup: [
                {
                    name: "In Progress",
                    selected: true,
                    status: [{ name: "None" }, { name: "Received" }, { name: "Suspended" }, { name: "Uploaded" }, { name: "Triaged" }],
                    views: [{ name: "By Rules" }, { name: "All" }, { name: "Unassigned" }]
                },
                {
                    name: "Processed",
                    selected: false,
                    status: [{ name: "Pending" }, { name: "Processed" }],
                    views: [{ name: "By Rules" }, { name: "All" }]
                },
                {
                    name: "Responded",
                    selected: false,
                    status: [{ name: "Forwarded" }, { name: "Replied" }],
                    views: [{ name: "By Rules" }, { name: "All" }]
                },
                {
                    name: "Deleted",
                    selected: false,
                    status: [{ name: "Deleted" }, { name: "Removed" }],
                    views: [{ name: "By Rules" }, { name: "All" }]
                }
            ]
        };

        return _.filter(statusMap.statusGroup, function (group) {
            var x = _.filter(group.views, function (v) {
                return v.name == view.name;
            });
            return x.length > 0;
        });
    };

    var getSelectedView = function () {
        return _.find($scope.viewStateMap.views, function (x) {
            return x.selected;
        });
    };

    var getSelectedRules = function () {
        return _.filter(getSelectedView().rules, function (x) {
            return x.id && x.checked;
        });
    };

    var getSelectedSources = function () {
        var sources = [];

        if ($scope.includeContentMap["NaviHealth"]) {
            sources.push("NaviHealth");
            sources.push("Ihde");
        }

        var arr = _.filter($scope.sourcesStateMap.sources, function (x) {
            return x.selected;
        });
        if (arr) {
            _.each(arr, function (x) {
                sources.push(x.value);
            });
        }
        if (arr.length === 0) {
            sources.push("none");
        }
        return sources;
    };

    var getSelectedStatuses = function () {
        var statuses = [];
        // Foreach selected statusNetwork, get the
        var selectedStatusGroups = _.filter(getSelectedView().statusGroup, function (x) {
            return x.selected;
        });
        var all = _.find(selectedStatusGroups, function (x) {
            return x.name == "All";
        });
        if (all) {
            // All is selected, so just use that list of statuses
            _.each(all.status, function (x) {
                statuses.push(x.name);
            });
        } else {
            _.each(selectedStatusGroups, function (x) {
                _.each(x.status, function (x) {
                    statuses.push(x.name);
                });
            });
            statuses = _.uniq(statuses);
        }
        return statuses;
    };

    var getSelectedProcessedTypes = function () {
        var types = [];
        types = _.filter($scope.processedTypes, function (x) {
            return x.selected === true;
        });
        var seltypes = _.map(types, "value");
        return seltypes;
    };

    var getSelectedSourceTypes = function () {
        const groups = getSelectedView().statusGroup;
        const sourceTypes = _.chain(groups)
            .filter(function (x) {
                return x.selected;
            })
            .map("sourceTypes")
            .flattenDeep()
            .compact()
            .uniq()
            .value();
        return sourceTypes;
    };

    var mergeStatuses = function (map1, map2) {
        _.each(map1.views, function (v) {
            var view = _.find(map2.views, function (v2) {
                return v.name === v2.name;
            });

            if (view) {
                _.each(v.statusGroup, function (g) {
                    var group = _.find(view.statusGroup, function (g2) {
                        return g.name === g2.name;
                    });

                    if (group) {
                        $.extend(true, g.status, group.status);
                    }
                });
            }
        });

        return map1;
    };

    var getViewStateMapFromStorage = function (isFirstRequestForView) {
        // Get the map from storage or the default. We will either
        // return it as is, or update parts of it.
        const $default = getDefaultViewStateMap();
        const oldMap = storage.get($scope.userName + ".intakeRulesMap");
        let map = storage.get("intakeRulesMap");

        // Merge statuses
        if (map) map = mergeStatuses(map, $default);
        else map = $default;

        // If the oldMap exists, merge it and then remove it from local storage.
        if (oldMap) {
            map = mergeStatuses(oldMap, map);
            storage.remove($scope.userName + ".intakeRulesMap");
        }

        return map;
    };

    var setViewStateMapFromStorage = function (viewStateMap) {
        return storage.set("intakeRulesMap", viewStateMap);
    };

    var fireRuleChangedEvent = function (persistsCurrentPage) {
        setViewStateMapFromStorage($scope.viewStateMap);
        var selectedView = getSelectedView();
        var rules = getSelectedRules();
        var statuses = getSelectedStatuses();
        //var sourceTypes = getSelectedSourceTypes();
        var isAssignedToSelf = _.some(getSelectedView().rules, function (x) {
            return x.checked && !x.id;
        });
        var sourceTypes = getSelectedSources();
        IntakeService.raiseRuleChanged(selectedView.value, selectedView.name, rules, statuses, sourceTypes, isAssignedToSelf, persistsCurrentPage);
    };

    $scope.getStatusVisibleClass = function () {
        if (getSelectedView().value === "unassigned") {
            return "hidden";
        }
    };

    $scope.canDownloadMessageOption = function () {
        if ($scope.interoperabilitySettings) {
            return $scope.interoperabilitySettings.downloadMessage;
        }
        return false;
    };

    $scope.getProcessTypeVisible = function (type) {
        return false;
        //var statuses = getSelectedStatuses();
        //if (!$scope.canDownloadMessageOption()) {
        //    return false;
        //}
        //if (statuses.indexOf("Processed") < 0 || type !== "Processed") {
        //    return false;
        //}
        //return true;
    };

    $scope.onViewChange = function (view, persistsCurrentPage) {
        _.each($scope.viewStateMap.views, function (v) {
            v.selected = view.value === v.value;
        });

        // Enable/disable controls
        $scope.viewStateMap.allRulesEnabled = view.value === "byRules";
        fireRuleChangedEvent(persistsCurrentPage);
    };

    $scope.onRuleChange = function ($event) {
        var rule = this.rule;
        var elem = angular.element($event.target);
        var isChecked = elem.is(":checked");
        rule.checked = isChecked;
        fireRuleChangedEvent();
    };

    $scope.onStatusChange = function (statusGroup) {
        // Get all selected statuses
        const statuses = getSelectedStatuses();
        setViewStateMapFromStorage($scope.viewStateMap);
        const sourceTypes = getSelectedSourceTypes();
        IntakeService.raiseStatusChanged(statuses, sourceTypes);
    };

    $scope.onProcessedTypeChange = function (processedType) {
        // Get all selected statuses
        var processdTypes = getSelectedProcessedTypes();
        var statuses = getSelectedStatuses();
        setViewStateMapFromStorage($scope.viewStateMap);
        var sourceTypes = getSelectedSourceTypes();
        IntakeService.raiseProcessedTypeChanged(processdTypes, statuses, sourceTypes);
    };

    $scope.getMyRules = function () {
        return _.filter($scope.getAllRules(), function (y) {
            return y.isMyRule;
        });
    };

    $scope.getAllRules = function () {
        return _.find($scope.viewStateMap.views, function (x) {
            return x.value === "byRules";
        }).rules;
    };

    $scope.getStatusGroupForView = function () {
        $scope.statusGroups = _.find($scope.viewStateMap.views, function (x) {
            return x.selected;
        }).statusGroup;
    };

    $scope.displayStatusGroup = function (name) {
        if (name !== "Deleted") return true;
        return $scope.userProfile.isAdmin;
    };

    var initAllAndMyRules = function (isFirstRequestForView) {
        var promises = [];

        if ($scope.userProfile.isAdmin) {
            // Get AllRules
            promises.push(RulesService.getAllRules());
        }

        // Get MyRules
        promises.push(RulesService.getRules($scope.userProfile.userId));

        $q.all(promises).then(function (data) {
            var view = _.find($scope.viewStateMap.views, function (v) {
                return v.value == "byRules";
            });

            var previousRules = view.rules ? view.rules : [];

            // Zero-out rules because we are going to recreate it.
            view.rules = [];

            if (data.length == 2) {
                // AllRules and MyRules: If MyRules is empty, there will still be 2 arrays [Array[#], Array[0]].
                $scope.viewStateMap.hasAllRules = data[0].length > 0;
                _.each(data[0], function (d) {
                    d.isMyRule = d.isMyRule || false;
                    view.rules.push(d);
                });
                $scope.viewStateMap.hasMyRules = data[1].length > 0 || !hideTriage();
                _.each(view.rules, function (r) {
                    var found = _.find(data[1], function (d) {
                        if (!isFirstRequestForView) d.checked = true;
                        return d.id == r.id;
                    });
                    r.isMyRule = found ? true : false; // found is an object
                });
            } else if (data.length == 1) {
                // MyRules only
                $scope.viewStateMap.hasMyRules = data[0].length > 0 || !hideTriage();
                _.each(data[0], function (d) {
                    d.isMyRule = true;
                    if (!isFirstRequestForView) d.checked = true;
                    view.rules.push(d);
                });
            }

            view.rules.splice(0, 0, { name: "Assigned To Me", id: 0, isMyRule: true });

            // If there are previous rules, make sure we sync them up. Do NOT add any rules, but only update
            // rules if they exist. The user could have deleted their rules by the time they return to this
            // page, so do NOT add them.
            if (previousRules.length > 0) {
                _.each(view.rules, function (currentRule) {
                    var prevRule = _.find(previousRules, function (pr) {
                        return currentRule.id == pr.id;
                    });
                    if (prevRule) {
                        currentRule.checked = prevRule.checked;
                    }
                });
            }

            // raise event
            $scope.onViewChange(getSelectedView(), true);
        });
    };

    function hideTriage() {
        return SubscriptionService.hasHideAction("triageRules");
    }

    (function () {
        $scope.userProfile = SessionService.getProfile();

        // When this is true, we get view state from local storage, otherwise we get defaults.
        $scope.isFirstRequestForView = $scope.$parent && $scope.$parent.isFirstRequestForView;

        // Init/Get MyRules, AllRules, FilteredViews, and Statuses
        $scope.viewStateMap = getViewStateMapFromStorage($scope.isFirstRequestForView);
        initAllAndMyRules($scope.isFirstRequestForView);

        // Get the current view
        var currentView = _.find($scope.viewStateMap.views, function (v) {
            return v.selected;
        });

        // Enable/disable controls
        $scope.viewStateMap.allRulesEnabled = currentView.value === "byRules";

        InteroperabilityService.get().then(function (response) {
            $scope.interoperabilitySettings = response.data;
        });

        $scope.getStatusGroupForView();
    })();
}
