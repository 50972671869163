import templateUrl from "./account.activate.component.html";

class AccountActivateController {
    constructor($location, $state, AccountProfileService, NotificationService) {
        this.$location = $location;
        this.$state = $state;
        this.AccountProfileService = AccountProfileService;
        this.NotificationService = NotificationService;
    }

    $onInit() {
        this.isTokenValid = true;
        this.isResendingInvitation = false;
        this.invitationSent = false;
        this.invitationNotFound = false;
        this.token = this.$location.search().t;

        this.AccountProfileService.validateToken(this.token)
            .then(() => {})
            .catch((err) => {
                if (err.status === 404) {
                    this.errorMessage = "An error occurred when trying to retrieve invitation. Please contact your System Administrator.";
                    this.invitationNotFound = true;
                } else {
                    this.errorMessage = "This invitation is expired.";
                }
                this.isTokenValid = false;
            });
    }

    submit() {
        let model = {
            token: this.token,
            password: this.password,
            confirmPassword: this.confirmPassword
        };
        this.AccountProfileService.activate(model).then(
            (res) => {
                this.$state.go("login");
            },
            (err, res) => {
                const e = err.data.modelState[Object.keys(err.data.modelState)[0]][0];
                this.errorMessage = e;
            }
        );
    }

    resendInvitation() {
        if (!this.isTokenValid && !this.isResendingInvitation) {
            this.isResendingInvitation = true;
            this.AccountProfileService.resendInvitation(this.token)
                .then((res) => {
                    this.invitationSent = true;
                    this.emailAddress = res.data;
                })
                .catch(() => {
                    this.errorMessage = "Error attempting to resend invitation. Please contact your System Administrator.";
                });
        }
    }
}

AccountActivateController.$inject = ["$location", "$state", "AccountProfileService", "NotificationService"];

export const accountActivateComponent = {
    templateUrl: templateUrl,
    controller: AccountActivateController
};
