export class ErrorLayoutCtrl {
    constructor($state, Auth0Service) {
        this.$state = $state;
        this.auth0Service = Auth0Service;
    }

    $onInit() {
        const isAuthenticated = this.auth0Service.isAuthenticated();

        if (!isAuthenticated) this.$state.go("login");
    }
}

ErrorLayoutCtrl.$inject = ["$state", "Auth0Service"];
