angular.module("kno2.factories").factory("AddressesCacheFactory", [
    "LocalStorageFactory",
    "SessionService",
    function (LocalStorageFactory, SessionService) {
        var userName = SessionService.getProfile().userName;
        var STORAGEKEY = userName + ".intakeAddresses";

        return {
            save: save,
            add: add,
            get: get,
            formatAddress: formatAddress
        };

        function save(addresses) {
            LocalStorageFactory.set(STORAGEKEY, addresses || []);
        }

        function add(addresses) {
            var addressesToCache = [];
            var storedAddresses = this.get();
            if (storedAddresses && addresses) {
                addressesToCache = _.union(storedAddresses, addresses);
            } else if (addresses) {
                addressesToCache = addresses;
            }
            if (addressesToCache.length > 0) {
                LocalStorageFactory.set(STORAGEKEY, addressesToCache);
            }
        }

        function get() {
            var addresses = LocalStorageFactory.get(STORAGEKEY);
            if (addresses) {
                addresses.sort();
            }
            return addresses || [];
        }

        function formatAddress(address) {
            if (!address) return address;
            var reg = new RegExp(/^\d+$/);
            if (!reg.test(address)) return address;
            var formatted = "";
            if (address.length === 10) {
                formatted = address.replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3");
            } else if (address.length === 11) {
                formatted = address.replace(/^(\d{1})(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3-$4");
            } else if (address.length === 12) {
                formatted = address.replace(/^(\d{2})(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3-$4");
            } else if (address.length === 13) {
                formatted = address.replace(/^(\d{3})(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3-$4");
            }
            return formatted;
        }
    }
]);
