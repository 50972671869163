export enum DownloadProfileFilenameOptions {
    MessageFrom,
    MessageDate,
    PatientId,
    PatientFirstName,
    PatientMiddleName,
    PatientLastName,
    PatientGender,
    PatientDOB,
    AttachmentDocumentType,
    AttachmentDate
}
