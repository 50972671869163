angular.module("kno2.settings").controller("DownloadProfileDeleteModalCtrl", [
    "$scope",
    "$uibModalInstance",
    "DownloadProfilesService",
    "NotificationService",
    "$timeout",
    "data",
    function ($scope, $uibModalInstance, DownloadProfilesService, NotificationService, $timeout, data) {
        $scope.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };

        $scope.saveChanges = function () {
            DownloadProfilesService.deleteProfile($scope.profile.id).then(
                function (data) {
                    NotificationService.success("Download Profile Deleted.");
                    $timeout(function () {
                        $uibModalInstance.close();
                    }, 500);
                },
                function (error) {
                    NotificationService.error(error);
                }
            );
        };

        var init = function () {
            $scope.profile = {};
            $scope.profile.id = data.id;
            $scope.profile.name = data.name;
        };

        init();
    }
]);
