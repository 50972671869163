import { DatePipe, TitleCasePipe } from "@angular/common";
import { Component, Input, OnInit, computed, inject, signal } from "@angular/core";
import { DirectoryClient } from "@kno2/shared/services";
import { AddressType, ContactType, DirectoryEntryQueryResult, DirectoryEntryType, Governance } from "@kno2/shared/services/directory-api.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep } from "lodash";
import { firstValueFrom } from "rxjs";
import { AdminOrganizationsQhinDetailsModalComponent } from "./admin-organizations-qhin-details-modal.component";

@Component({
    selector: "kno2-admin-organizations-qhin",
    standalone: true,
    imports: [DatePipe, TitleCasePipe, AdminOrganizationsQhinDetailsModalComponent],
    templateUrl: "./admin-organizations-qhin.component.html"
})
export class AdminOrganizationsQhinComponent implements OnInit {
    DirectoryEntryType = DirectoryEntryType;
    @Input() public organization: any;

    public directoryEntry = signal<DirectoryEntryQueryResult>(null);
    public childDirectoryEntries = signal<DirectoryEntryQueryResult[]>([]);
    public allDirectoryEntries = computed(() => [this.directoryEntry(), ...this.childDirectoryEntries()].filter(Boolean));

    private ngbModalService = inject(NgbModal);
    private directoryClient = inject(DirectoryClient);

    async ngOnInit(): Promise<void> {
        try {
            this.directoryEntry.set(await firstValueFrom(this.directoryClient.directoryEntriesGET2(this.organization.organizationId)));
        } catch (e) {}
    }

    public async showQhinDetailsModal(directoryEntry: DirectoryEntryQueryResult) {
        const parentDirectoryEntry =
            directoryEntry.directoryEntryType === DirectoryEntryType.Participant ? await this.getKno2DirectoryEntry() : this.directoryEntry();

        const editableDirectoryEntry = cloneDeep(
            directoryEntry.id
                ? directoryEntry
                : {
                      name: this.organization.name,
                      homeCommunityId: this.organization.homeCommunityId,
                      parentId: parentDirectoryEntry?.id,
                      governance: Governance.Tefca,
                      directoryEntryType: directoryEntry.directoryEntryType,
                      contacts: [
                          {
                              contactType: ContactType.Organization,
                              addresses: [
                                  {
                                      addressType: AddressType.OrganizationLocation,
                                      street1: this.organization.address.street,
                                      city: this.organization.address.city,
                                      zipCode: this.organization.address.zip,
                                      state: this.organization.address.state,
                                      country: this.organization.address.country
                                  }
                              ]
                          }
                      ]
                  }
        );

        const { componentInstance, result } = this.ngbModalService.open(AdminOrganizationsQhinDetailsModalComponent, {
            windowClass: "modal-1000",
            backdrop: false
        }) as { componentInstance: AdminOrganizationsQhinDetailsModalComponent; result: Promise<any> };

        componentInstance.directoryEntry.set(editableDirectoryEntry);
        componentInstance.organizationId.set(this.organization.organizationId);
        componentInstance.isNewEntry.set(!directoryEntry?.id);

        await result;

        try {
            this.directoryEntry.set(await firstValueFrom(this.directoryClient.directoryEntriesGET2(this.organization.organizationId)));
        } catch (e) {}
    }

    private async getKno2DirectoryEntry(): Promise<DirectoryEntryQueryResult> {
        const queryResult = await firstValueFrom(
            this.directoryClient.search({
                name: "Kno2",
                governance: Governance.Tefca,
                directoryEntryType: DirectoryEntryType.Qhin
            })
        );

        if (queryResult.length !== 1) throw new Error(`Directory API returned unexpected results when querying for Kno2 entry`);

        return queryResult[0];
    }
}
