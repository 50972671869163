import { Component, inject, input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { AccountEditProfileServiceToken } from "@kno2/interop";
import { NoOpLink } from "@kno2/shared/directives";

@Component({
    selector: "kno2-profile-menu",
    standalone: true,
    imports: [RouterLink, NoOpLink],
    templateUrl: "./profile-menu.component.html"
})
export class ProfileMenuComponent {
    private readonly upgrade = inject(UpgradeModule);
    private readonly authService = this.upgrade.$injector.get("Auth0Service");
    private readonly cacheService = this.upgrade.$injector.get("CacheService");
    private readonly accountEditProfileService = inject(AccountEditProfileServiceToken);

    protected currentUser = input.required<{ fullName: string; userId: string }>();

    public editProfile({ changingPassword }: { changingPassword: boolean } = { changingPassword: false }): void {
        this.accountEditProfileService.editProfile(changingPassword, this.currentUser().userId);
    }

    public logout(): void {
        this.cacheService.clear();
        this.authService.logout();
    }
}
