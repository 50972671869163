import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AccountService {
    private httpClient = inject(HttpClient);

    public changeOrganization = (orgId: string): Promise<string | null> => firstValueFrom(this.changeOrganization$(orgId));

    private changeOrganization$(orgId: string): Observable<string | null> {
        return this.httpClient.post<string | null>("/api/account/change", { orgId });
    }
}
