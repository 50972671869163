import { AfterProcessMessageOptions } from "./after-process-message.options";

class UserPreferencesController {
    public afterProcessMessageOptions: AfterProcessMessageOptions;

    $onInit() {
        this.afterProcessMessageOptions = {
            DoNothing: "Do Nothing",
            ReturnToIntake: "Return to Intake"

            // GoToNextMessage: 'Go to Next Message'
            // -DB - Postponing this feature for clarification - https://inofile.atlassian.net/browse/KEW-104
        };
    }
}

class UserPreferencesComponent {
    public templateUrl: string;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./user-preferences.component.html");
        this.controller = UserPreferencesController;
        this.bindings = {
            userPreferences: "="
        };
    }
}

export const userPreferencesComponent = new UserPreferencesComponent();
