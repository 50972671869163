import { DownloadProfileFilenameOptions } from "./download-profile-filename-options";

declare const dragula;
declare const angular;

class FilenameOptionsController {
    public static $inject: Array<string> = ["$scope"];

    public selectedFilenameFields: string[];
    public availableFilenameFields: string[];
    public isProfileInit: boolean;

    private readonly availableFilenameFieldsId = "AvailableFilenameFields";
    private readonly selectedFilenameFieldsId = "SelectedFilenameFields";

    constructor(private $scope) {}

    public $onInit(): void {
        this.configureDragula();
    }

    public $onChanges(changes: any): void {
        if (changes.isProfileInit.currentValue) {
            this.isProfileInit = changes.isProfileInit.currentValue;
            this.availableFilenameFields = this.getAvailableFilenameFields();
        }
    }

    private configureDragula(): void {
        const ctrl = this;
        dragula([document.getElementById(ctrl.availableFilenameFieldsId), document.getElementById(ctrl.selectedFilenameFieldsId)], {
            removeOnSpill: false,
            accepts: (el, target) => angular.element(target).attr("id") === ctrl.selectedFilenameFieldsId
        }).on("drop", function (el, target, source) {
            ctrl.moveItem(el, target, source);
        });
    }

    public getAvailableFilenameFields(): string[] {
        return Object.keys(DownloadProfileFilenameOptions).filter((x) => !(parseInt(x) >= 0) && !this.hasFieldInFilename(x));
    }

    public hasFieldInFilename(field: string): boolean {
        return this.selectedFilenameFields && this.selectedFilenameFields.indexOf(field) > -1;
    }

    public addField(field: string): void {
        this.selectedFilenameFields.push(field);
        this.availableFilenameFields = this.getAvailableFilenameFields();
    }

    public removeField(field: string): void {
        const index = this.selectedFilenameFields.indexOf(field);
        this.selectedFilenameFields.splice(index, 1);
        this.availableFilenameFields = this.getAvailableFilenameFields();
    }

    public moveItem(el: any, target: any, source: any): void {
        const targetIndex = angular.element(el).index();
        const enumKey = angular.element(el).attr("data-key");
        const isFromSelected = angular.element(source).attr("id") === this.selectedFilenameFieldsId;
        const isSameContainer = target === source;

        const originalList = isFromSelected ? this.selectedFilenameFields : this.availableFilenameFields;
        const origIndex = originalList.indexOf(enumKey);

        if (!this.selectedFilenameFields) this.selectedFilenameFields = [];

        if (!isSameContainer) {
            angular.element(el).remove();
            this.availableFilenameFields.splice(origIndex, 1);
        } else {
            this.selectedFilenameFields.splice(origIndex, 1);
        }

        this.selectedFilenameFields.splice(targetIndex, 0, enumKey);
        this.$scope.$apply();
    }
}

export class FilenameOptionsComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./filename-options.component.html");
        this.controller = FilenameOptionsController;
        this.bindings = {
            selectedFilenameFields: "=",
            isProfileInit: "<"
        };
    }
}
