angular.module("kno2.shared.settings").controller("DocumentTypesEditModalCtrl", [
    "$scope",
    "$timeout",
    "$uibModalInstance",
    "data",
    "DocumentTypesService",
    "NotificationService",
    "SessionService",
    function ($scope, $timeout, $uibModalInstance, data, DocumentTypesService, NotificationService, SessionService) {
        $scope.setForm = function (form) {
            $scope.form = form;
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss("cancel");
        };
        $scope.saveChanges = function () {
            // Form validations
            $scope.form.$valid = true;
            if ($scope.form.$error.required) {
                _.each($scope.form.$error.required, function (x) {
                    $scope.form.$valid = false;
                });
            }

            if ($scope.form.$valid) {
                $scope.form.$valid = !$scope.form.documentTypeName.$error.unique;
            }
            if ($scope.form.$valid) {
                $scope.form.$valid = !$scope.form.documentTypeName.$error.documenttypespecialchar;
            }

            // Save changes if form valid
            if ($scope.form.$valid) {
                var functionName = $scope.documentType.id ? "putDocumentType" : "postDocumentType";
                DocumentTypesService[functionName]($scope.documentType).then(
                    function (response) {
                        NotificationService.success("The document type was saved successfully.");
                        $uibModalInstance.close($scope.documentType);
                    },
                    function (response) {
                        NotificationService.serverError(response.data.modelState, "There was an error saving this document type.");
                    }
                );
            }
        };

        // This provides the necessary call information for the k2-unique directive.
        $scope.documentTypeExists = function () {
            return {
                serviceFunction: DocumentTypesService.isDocumentTypeExists,
                errorMessage: "This document type already exists, please enter a different one."
            };
        };

        if (data.id) {
            DocumentTypesService.getDocumentType(data.id).then(function (result) {
                $scope.documentType = result;
                $timeout(function () {
                    $("input[name*='documentTypeName']").focus().select();
                }, 500);
            });
        } else {
            $scope.documentType = {
                organizationId: SessionService.getProfile().organizationId
            };
            $timeout(function () {
                $("input[name*='documentTypeName']").focus().select();
            }, 500);
        }
    }
]);
