import { Routes } from "@angular/router";
import { routes as settingsRoutes } from "@kno2/features/settings";
import { routes as supportRoutes } from "@kno2/features/support";
import { tokenGuard } from "@kno2/interop";
import { HomeComponent } from "./common/layout";

export const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
        canActivateChild: [tokenGuard],
        children: [
            ...settingsRoutes,
            ...supportRoutes,
            {
                path: "legalinformation",
                loadComponent: () => import("./features/eula/legal-information/legal-information.component").then((c) => c.LegalInformationComponent)
            }
        ]
    },
    {
        path: "policies/privacy",
        loadComponent: () => import("./features/eula/policy/policy.component").then((c) => c.PolicyComponent),
        data: { policyType: "Privacy" }
    },
    {
        path: "policies/termsofuse",
        loadComponent: () => import("./features/eula/policy/policy.component").then((c) => c.PolicyComponent),
        data: { policyType: "EULA" }
    },
    {
        path: "**",
        loadComponent: () => import("./interop/components/empty/empty.component").then((c) => c.EmptyComponent)
    }
];
