import templateUrl from "./auth0-callback.component.html";

class Auth0CallbackController {
    constructor($window, $state, LocalStorageFactory, StateRoutingService, Auth0Service, SessionService, FeatureService) {
        this.$window = $window;
        this.$state = $state;
        this.localStorageFactory = LocalStorageFactory;
        this.stateRoutingService = StateRoutingService;
        this.auth0Service = Auth0Service;
        this.sessionService = SessionService;
        this.featureService = FeatureService;
    }

    $onInit() {
        const getErrorDescriptionFromUrl = () => {
            return this.$window.location.hash
                .substring(1)
                .split("&")
                .map((item) => item.split("="))
                .find((parts) => parts[0] === "error_description")?.[1];
        };

        const urlErrorDescription = getErrorDescriptionFromUrl();

        this.auth0Service
            .callbackRenewTokens()
            .then(() => this.complete())
            .catch((err) => {
                const errorDescription =
                    err.description === "[object Object]" && urlErrorDescription ? decodeURIComponent(urlErrorDescription) : err.description;

                this.$state.go("authError", {
                    error: {
                        code: err.code,
                        description: errorDescription
                    }
                });
            });
    }

    async complete() {
        const redirectUrl = this.localStorageFactory.get("redirectUrl");
        if (!hasValidRedirectUrl(redirectUrl)) redirectUrl = null;

        this.localStorageFactory.remove("redirectUrl");

        await this.sessionService.refreshProfile();
        await this.featureService.load({ reload: true });

        if (redirectUrl) this.$window.location = redirectUrl;
        else {
            const targetState = await this.stateRoutingService.getState();
            this.stateRoutingService.goTo(targetState);
        }
    }
}

Auth0CallbackController.$inject = ["$window", "$state", "LocalStorageFactory", "StateRoutingService", "Auth0Service", "SessionService", "FeatureService"];

export const auth0CallbackComponent = {
    templateUrl: templateUrl,
    controller: Auth0CallbackController
};

function hasValidRedirectUrl(redirectUrl) {
    const blacklistRedirectUrls = ["/unauthorized", "/access-denied", "/auth-error", "/"];

    const blacklistedStartsWith = ["/callback", "/account/login", "/account/register", "/account/logout", "/?email="];

    if (_.includes(blacklistRedirectUrls, redirectUrl)) return false;

    for (let i = 0; i <= blacklistedStartsWith.length; i++) {
        if (_.startsWith(redirectUrl, blacklistedStartsWith[i])) return false;
    }

    return true;
}
