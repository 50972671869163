"use strict";

export class Auth0Service {
    constructor($state, $window, angularAuth0, $timeout, $q, LocalStorageFactory, appConfig) {
        this.$state = $state;
        this.$window = $window;
        this.angularAuth0 = angularAuth0;
        this.$timeout = $timeout;
        this.$q = $q;
        this.localStorageFactory = LocalStorageFactory;
        this.appConfig = appConfig;

        this.connections = {
            pcc: "PointClickCare"
        };
    }

    getIdToken() {
        return this.idToken;
    }

    getAccessToken() {
        return this.accessToken;
    }

    login(loginType, params) {
        const obj = {
            scope: "openid offline_access",
            ...params
        };
        if (this.connections[loginType]) {
            obj.connection = this.connections[loginType];
        } else {
            obj.connection = loginType;
        }
        this.angularAuth0.authorize(obj);
    }

    handleAuthentication() {
        return this.$q((resolve, reject) => {
            this.angularAuth0.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.localLogin(authResult);
                    resolve();
                } else if (err) {
                    reject(err);
                }
            });
        });
    }

    localLogin(authResult) {
        this.localStorageFactory.set("auth", {
            isLoggedIn: true,
            expiresAt: authResult.expiresIn * 1000 + new Date().getTime()
        });
        this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
        this.accessToken = authResult.accessToken;
        this.idToken = authResult.idToken;
    }

    callbackRenewTokens() {
        this.$window.location.hash = "";
        return this.renewTokens();
    }

    scheduleRenewTokens() {
        const currentTime = new Date().getTime();
        const auth = this.localStorageFactory.get("auth") || {};
        const scheduledTime = auth.expiresAt ? auth.expiresAt - currentTime - 60000 : 1000 * 60 * 15;

        if (this.renewTokensTimeoutFn) this.$timeout.cancel(this.renewTokensTimeoutFn);
        this.renewTokensTimeoutFn = this.$timeout(() => this.renewTokens(), scheduledTime);
    }

    renewTokens() {
        if (this._renewalPromise) {
            return this._renewalPromise;
        }

        this._renewalPromise = this.$q((resolve, reject) => {
            this.angularAuth0.checkSession({}, (err, result) => {
                this._renewalPromise = null;

                if (err) {
                    reject(err);
                } else {
                    this.scheduleRenewTokens();
                    this.localLogin(result);
                    resolve();
                }
            });
        });

        return this._renewalPromise;
    }

    logout() {
        const profileData = this.localStorageFactory.get("ProfileData") || {};
        const logoutUrl = this.isSingleSignOnUser(profileData) ? `${this.appConfig.appUrl}/account/logout` : this.appConfig.appUrl;

        this.localStorageFactory.remove("auth");
        this.localStorageFactory.remove("features");
        this.localStorageFactory.remove("redirectUrl");
        this.localStorageFactory.remove("ProfileData");
        this.accessToken = "";
        this.idToken = "";
        this.expiresAt = 0;

        this.angularAuth0.logout({ returnTo: logoutUrl });
    }

    isAuthenticated() {
        const auth = this.localStorageFactory.get("auth") || {};
        return auth.isLoggedIn && new Date().getTime() < auth.expiresAt;
    }

    isSingleSignOnUser(profile) {
        return profile.authStrategy !== "usernamepassword" || !profile.authStrategy;
    }
}

Auth0Service.$inject = ["$state", "$window", "angularAuth0", "$timeout", "$q", "LocalStorageFactory", "appConfig"];
