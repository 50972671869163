import { Component, OnDestroy, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { WINDOW_TOKEN } from "@kno2/core";
import { SocketService, TooltipsService } from "@kno2/shared/services";

@Component({
    imports: [RouterOutlet],
    selector: "app-root",
    templateUrl: "./app.component.html",
    standalone: true
})
export class AppComponent implements OnDestroy {
    private window = inject(WINDOW_TOKEN);
    private socketService = inject(SocketService);
    private tooltipsService = inject(TooltipsService);

    public async ngOnInit(): Promise<void> {
        try {
            this.socketService.init();
            this.tooltipsService.init();
            this.window.onbeforeunload = () => this.ngOnDestroy();
        } catch (err) {
            console.error(err);
        }
    }

    public ngOnDestroy(): void {
        this.window.onbeforeunload = null;
        this.socketService?.disconnect();
    }
}
