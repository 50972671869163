import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";
import { AddIntegrationPatient } from "./integrations.models";

@Injectable({
    providedIn: "root"
})
export class IntegrationsService {
    public addIntegrationPatient = (patient: AddIntegrationPatient): Promise<void> => firstValueFrom(this.addIntegrationPatient$(patient));

    constructor(private httpClient: HttpClient) {}

    private addIntegrationPatient$(patient: AddIntegrationPatient): Observable<void> {
        return this.httpClient.post<void>("/api/integrations/patients/add", patient);
    }
}
