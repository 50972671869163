import { Component, OnInit, inject } from "@angular/core";
import { RouterLink } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { FeatureToggleDirective, NoOpLink, ShowForRoleInDirective } from "@kno2/shared/directives";
import { OrgSwitcherComponent } from "./org-switcher/org-switcher.component";
import { ProfileMenuComponent } from "./profile-menu/profile-menu.component";
import { SettingsMenuComponent } from "./settings-menu/settings-menu.component";

@Component({
    selector: "kno2-header",
    standalone: true,
    imports: [OrgSwitcherComponent, ProfileMenuComponent, SettingsMenuComponent, ShowForRoleInDirective, FeatureToggleDirective, NoOpLink, RouterLink],
    templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit {
    private readonly upgrade = inject(UpgradeModule);
    private readonly sessionService = this.upgrade.$injector.get("SessionService");
    private readonly $uibModal = this.upgrade.$injector.get("$uibModal");
    protected currentUser;

    public ngOnInit(): void {
        this.currentUser = this.sessionService.getProfile();
    }

    public openInvitationModal(): void {
        const { result } = this.$uibModal.open({
            templateUrl: require("../../../../../App/src/common/user-invitation/account.dashboard.user-invitation-modal.html"),
            controller: "UserInvitationModalCtrl"
        });

        result.then(
            () => {},
            () => {}
        );
    }
}
