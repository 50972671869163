import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";

export const featureGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const upgrade = inject(UpgradeModule);
    const featuresService = upgrade.$injector.get("FeatureService");
    const sessionService = upgrade.$injector.get("SessionService");

    const canActivate = featuresService.isEnabled(route.data?.features);
    if (canActivate) return true;

    const redirectUrl = sessionService.userInRole(["Administrator"]) ? "/account/dashboard" : "/intake";
    router.navigateByUrl(redirectUrl);
    return false;
};
